import * as React from "react";
import { SVGProps } from "react";
const SvgCopyRights = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 17.5a7.5 7.5 0 1 0 0-15 7.5 7.5 0 0 0 0 15Z"
      stroke="#818A8B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.5 11.875a3.125 3.125 0 1 1 0-3.75"
      stroke="#818A8B"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgCopyRights;
