import { theme } from "theme";


export const isSizeXXL = () => {
   return parseFloat(theme.breakpoints.xxl.value.replace('px',''))
}


export const isSizeXL = () => {
   return parseFloat(theme.breakpoints.xl.value.replace('px',''))
}

export const isSizeLG = () => {
   return parseFloat(theme.breakpoints.lg.value.replace('px',''))
}
export const isSizeMD = () => {
   return parseFloat(theme.breakpoints.md.value.replace('px',''))
}
export const isSizeSM = () => {
   return parseFloat(theme.breakpoints.sm.value.replace('px',''))
}
