import * as React from "react";
import { SVGProps } from "react";
const SvgTailLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="647"
    height="592"
    viewBox="0 0 647 592"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      opacity={0.1}
      clipPath="url(#TailLeft_svg__a)"
      filter="url(#TailLeft_svg__b)"
    >
      <path
        d="M-35.773 402.762a20.265 20.265 0 0 0 11.403 18.2c12.12 6.149 27.62 4.881 27.706-12.617.086-17.497.617-104.79 106.377-107.26 105.76-2.471 146.415 59.886 184.665 93.141 38.249 33.255 97.91 92.698 169.846 78.875 71.936-13.823 130.472-75.222 133.215-166.988 2.744-91.766-73.362-190.375-175.883-175.338 0 0-3.624 1.768-1.793-2.26 1.83-4.028 8.202-20.197 8.202-20.197l-70.596 30.638 68.09 31.323-6.645-22.034s-2.214-4.007 4.025-5.358c6.239-1.351 93.441-1.818 135.346 70.703 41.904 72.52 32.874 157.305-22.148 207.775-55.022 50.47-127.717 40.231-158.331 18.662a461.575 461.575 0 0 1-93.528-81.712c-50.932-56.379-106.725-89.573-174.218-90.667-76.146-1.309-145.98 45.154-145.733 135.114Z"
        fill="#fff"
      />
      <path
        d="M-4.083 406.257s-2.904-95.64 84.713-108.736c0 0 90.297-20.088 163.427 43.264 71.152 61.681 71.056 77.156 136.204 114.5a122.122 122.122 0 0 0 106.451 9.214 137.601 137.601 0 0 1-103.574-11.944c-51.727-29.171-84.25-68.057-109.401-92.109-25.15-24.053-65.406-61.976-119.535-70.904-54.128-8.929-110.463-5.952-141.134 37.954-30.671 43.907-17.151 78.761-17.151 78.761Z"
        fill="#fff"
        style={{
          mixBlendMode: "color-dodge",
        }}
      />
      <path
        d="M580.716 375.802a192.618 192.618 0 0 0 8.677-116.31c-16.373-60.22-59.702-105.326-95.143-117.187-35.441-11.86-63.653-11.517-76.758-7.073 0 0-4.885 2.18-2.185-2.302 2.7-4.482 9.952-21.908 9.952-21.908l-63.455 27.955s30.734.367 51.293-.038a141.435 141.435 0 0 1 19.95-1.809c15.265-.777 85.742 8.039 120.976 59.889 35.234 51.851 50.968 93.539 26.693 178.783Z"
        fill="#fff"
      />
      <path
        d="m366.259 141.507 56.953 25.644-9.77-25.197-47.183-.447Z"
        fill="#fff"
        style={{
          mixBlendMode: "color-dodge",
        }}
      />
    </g>
    <defs>
      <clipPath id="TailLeft_svg__a">
        <path
          fill="#fff"
          transform="rotate(-162.56 303.318 256.025)"
          d="M0 0h590.085v434.301H0z"
        />
      </clipPath>
      <filter
        id="TailLeft_svg__b"
        x={-51}
        y={0}
        width={701.122}
        height={601.188}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_756_256" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_756_256"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={10} />
        <feGaussianBlur stdDeviation={5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect2_innerShadow_756_256" />
      </filter>
    </defs>
  </svg>
);
export default SvgTailLeft;
