import * as React from "react";
import { SVGProps } from "react";
const SvgFooterLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={29}
    height={32}
    viewBox="0 0 29 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#FooterLogo_svg__a)" fill="#FFFFEF">
      <path d="M23.94 9.736c.364 1.226.67 3.35-.19 6.129 0 0-.497 1.95-2.543 2.969 0 0-1.53.742-1.53 2.296v2.002s-1.97.57-4.303 3.367h-1.548c-2.314-2.797-4.284-3.367-4.284-3.367v-2.02c0-1.553-1.53-2.295-1.53-2.295-2.064-1.019-2.562-2.97-2.562-2.97-.86-2.813-.535-4.971-.172-6.18 0 .018-.019.035-.019.052 0 0 .115 4.765 4.666 7.855 0 0 .67.466 1.377.604a.643.643 0 0 1 .133.034c.517.087.861.277.861.277v-.018c.02 0 .02.018.02.018v-.104c.037-2.123-2.276-3.642-2.276-3.642-.956-.605-1.645-1.243-2.142-1.882-.02-.017-.038-.052-.057-.07-.02-.016-.039-.034-.039-.05-.038-.053-.057-.087-.095-.139-.038-.034-.058-.086-.096-.138-.038-.052-.076-.104-.095-.155-.115-.19-.21-.38-.306-.553a1.117 1.117 0 0 0-.058-.138c-.019-.07-.057-.12-.076-.19-.02-.052-.038-.12-.077-.173a5.532 5.532 0 0 1-.19-.638.919.919 0 0 1-.04-.225c-.018-.069-.018-.138-.037-.19 0-.034 0-.069-.02-.086v-.138c.058-1.623-.592-2.71-.592-2.71C4.552 3.867 1.932.12 1.837 0c.095.086 3.135 2.9 5.583 4.091 0 0 1.874 1.157 4.168 1.226h6.062c2.295-.069 4.168-1.226 4.168-1.226 2.41-1.19 5.45-4.005 5.546-4.091-.077.12-2.716 3.867-4.34 7.25 0 0-.632 1.088-.594 2.71 0 0 0 .294-.095.76-.249 1.053-.995 2.693-3.194 4.04 0 0-2.218 1.45-2.275 3.487 0 .052-.02.104-.02.155v.104h.02s.344-.19.86-.276c.02 0 .02 0 .058-.018H17.842c.726-.12 1.433-.621 1.433-.621 4.57-3.09 4.666-7.855 4.666-7.855Z" />
      <path d="M14.592 32c1.81 0 2.596-.602 2.596-.602 1.572-1.067 2.767-.947 2.767-.947 2.58.087 2.75-1.703 2.75-1.703.206-1.6 2.068-2.667 2.068-2.667 1.998-1.393 3.296-4.009 3.296-4.009 2.392-5.282-.58-9.102-.58-9.102s2.681 5.833-1.657 10.186c0 0-.633.774-1.76.998 0 0-1.793.035-2.374.654 0 0-.496.43-1.06.654 0 0-.939.292-1.742 1.651 0 0-.717 1.067-1.571 1.515 0 0-.991.499-2.016 1.892 0 0-.41.74-.666.757h-.017c-.256-.017-.666-.757-.666-.757-1.025-1.41-2.016-1.892-2.016-1.892-.871-.43-1.572-1.515-1.572-1.515-.82-1.359-1.742-1.651-1.742-1.651-.564-.224-1.06-.654-1.06-.654-.597-.602-2.374-.654-2.374-.654-1.127-.224-1.759-.998-1.759-.998C-.987 18.803 1.712 12.97 1.712 12.97s-2.973 3.82-.581 9.102c0 0 1.298 2.616 3.297 4.01 0 0 1.862 1.066 2.067 2.666 0 0 .17 1.79 2.75 1.703 0 0 1.179-.12 2.767.947-.017 0 .769.602 2.58.602Z" />
    </g>
    <defs>
      <filter
        id="FooterLogo_svg__a"
        x={0.2}
        y={0}
        width={28.8}
        height={33}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_1001_203" />
      </filter>
    </defs>
  </svg>
);
export default SvgFooterLogo;
