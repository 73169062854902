import { Container, Button, Box } from "components/elements";
import { Flex } from "components/elements/Flex";
import { Heading } from "components/elements/Heading";
import { Paragraph } from "components/elements/Paragraph";
import TextEffect from "components/elements/TextEffect";
import React, { useLayoutEffect,useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import useViewport from "hooks/useViewport";
import { isSizeXXL } from "utils/mediaQueryParser";
import Slider2 from "./components/Slider2";
import toNumber from "utils/numbers";
import { GalleryDescription } from "./styles";
gsap.registerPlugin(ScrollTrigger);

const Gallery = () => {
  const {width} = useViewport()
  const text1Ref  = useRef<HTMLHeadingElement>(null)
  const ref2  = useRef<HTMLHeadingElement>(null)
  useLayoutEffect(() => {
    // ScrollTrigger.create({
    //   trigger: '.anim',
    //   start: "top bottom",
    //   onEnter : () => animateText('#map3',0.6,100),
    //   toggleActions: "restart pause reverse pause",
    //   onLeaveBack: self => self.disable()
    //  });
    let value  
    if(text1Ref.current){
      value = text1Ref.current.getBoundingClientRect().height      
    }
    let tl =  gsap.timeline({
      scrollTrigger: {
        trigger: ".text1",
        pin: false,
        scrub: true,
        start: 'top bottom',
        end: `+=${ref2.current && value && value + toNumber(getComputedStyle(ref2.current).marginTop)+toNumber(getComputedStyle(ref2.current).lineHeight)}`,
        markers: false,
        once:true,
        toggleActions:"restart pause reverse none",
      }
    })
    tl.fromTo('#map3', {
      'scale': 100,
       attr: {scale: 100},
     },{
         duration: 0.6,
       ease: "none",
       'scale': 0,
        attr: {scale: 0},
     });
  },[])

  return (
   <Box className="landingBg" css={{zIndex:0, "@lg":{zIndex:"3 !important"}}}>
     <Container  css={{p:"$space$5 0 0 0",  
     "@md":{
      overflowX:"hidden",
      p:"$space$9 0 0 0"
     },
     "@lg":{
      overflowX:"initial",
     }}}  className="panel ">
      <Flex direction="column" align="center">
            <Heading ref={text1Ref} className="text1 anim" tag="h3" css={{color: "$primary3", "@lg":{
              // p:"0 $space$15"
            }}}>
              
              LiquiDevil is the most advanced, innovative cutting edge platform for trading NFTs.
            </Heading>
            <TextEffect url=":text1:" id="map3"/>
            <GalleryDescription>
              <Paragraph className="galleryText" ref={ref2}  size="lg">
                Its revolutionary AMM design allows the pool creator to deploy a fully customized bonding curve that assigns a rarity {width > isSizeXXL() ? (<br/>) : null } premium to each NFT according to its characteristic traits, & values them accordingly. This leads to unparalleled liquidity concentration by trading both floor and rare NFTs in the same pool!
              </Paragraph>
            </GalleryDescription>
          </Flex>

        <Box css={{display:"none","@md":{
          "display":"block"
        }}}>
          {/* <Slider  /> */}
          <Slider2/>
        </Box>
      {/* <Flex justify="center" css={{mt:"$space$8",mb:"$space$12"}}> */}
      <Flex justify="start" css={{
        pb:"72px",
      "@md":{
        justifyContent:"center",
        pb:"96px",
        pt:"56px"
      },
      "@lg":{
        pt:"$space$8",pb:"$space$12",
      }}}>

        <Button  gradient="g1" color="primary">
          Whitepaper
        </Button>
        <Button  css={{display:"none" , ml:"$space$5","@sm":{display:"none"},"@md":{
          ml:"$space$4",
          display:"block"
        } , "@lg":{
          ml:"$space$5",
        }}} color="brown">
          Launch App
        </Button>
      </Flex>
    </Container>
   </Box>

  );
};

export default Gallery;