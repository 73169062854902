 import { Box } from "components/elements";
 import { Paragraph } from "components/elements/Paragraph";
import { Diamond, Ideas, Stocks } from "components/Icons";
import { useEffect } from "react";
import { FeatureDescription, FeatureDetails, GradientShadow } from "./styles";
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { Flex } from "components/elements/Flex";

gsap.registerPlugin(ScrollTrigger)

const Detail = () => {
  useEffect(() => {
    gsap.timeline({
      scrollTrigger: {
        trigger: "#wrap",
        pin: true,
        scrub: 0.2,
        start: 'top top',
      }
    })
      .to('#logo', {
        rotation: 360 * 5,
        duration: 2, ease: 'none',
      })
    gsap.timeline({
      scrollTrigger: {
        trigger: "#logo2",
        pin: true,
        scrub: 0.2,
        start: 'top top',
        end: '+=10000',
      }
    })
      .to('#logo2', {
        rotation: 360 * 5,
        duration: 2, ease: 'none',
      })
  }, [])
  return (
    <Box css={{p:"0 55px 55px 55px", 
    "@md":{
      p: "$space$5 $space$11 0 $space$11" 

    },
    "@xl":{
      p: "$space$5 $space$21 0 $space$21" 
    } }}>
      <Box  css={{"@md": {
        gridTemplateColumns: "repeat(2, 1fr)",
        display:"grid",
        columnGap:"25%"
      } , "@lg":{
        columnGap:"initial",
        gridTemplateColumns: "repeat(3, 1fr)",
      }}}>
        <FeatureDetails>
          <Diamond className="float" />
          <FeatureDescription css={{  color: "$accent3" }} size="xl">
            Never undervalue your NFTs ever again
          </FeatureDescription>
          <GradientShadow css={{ $$color: "$colors$accent3",$$opacity:"0.5" }} />

        </FeatureDetails>

        <Flex align="center" justify="center" css={{display:"none","@lg":{display:"flex"}, height: "480px", width: "480px" }}>
          {/* <ScrollRotate method={"perc"} animationDuration={0.1} loops={5}> */}

          <Flex id="logo" css={{ position: "relative", backgroundColor: "transparent", height: "2px", width: "2px", borderRadius: "50%" }}>
             <Box css={{ position: "absolute", top: "-198px", right: "-50px" }} as="img" src="assets/icons/six.svg" />
            
            <Box css={{ position: "absolute", right: "13px", top: "-18px" }} as="img" src="assets/icons/sixthree.svg" />
            <Box css={{ position: "absolute", left: "12px", top: "-16px" }} as="img" src="assets/icons/sixtwo.svg" />
          </Flex>
          {/* </ScrollRotate> */}
        </Flex>
        {/* <RotateLogo id="logo2" /> */}


        {/* <Box id="logo" as="img" src="assets/icons/RotateLogo.svg"/> */}
        <FeatureDetails>
          <Stocks  className="float2" />
          <FeatureDescription css={{  color: "$primary3" }} size="xl">
            Make the most out of your investments
          </FeatureDescription>
          <GradientShadow css={{ $$color: "$colors$primary3" }} />

        </FeatureDetails>
        <FeatureDetails css={{ mb: "$space$17 ","@md":{
          mt:"35px",
          gridColumn: "span 2",
          placeSelf: "center"
        },"@lg":{
          gridColumn: "span 3"
        }}}>
          <Ideas className="float3" />
          <FeatureDescription css={{  color: "$secondary3" }} size="xl">
            Unravel new opportunities to make money
          </FeatureDescription>
          <GradientShadow css={{ $$color: "$colors$secondary3" }} />
        </FeatureDetails>
      </Box>
      {/* <BorderLine /> */}

    </Box>
  );
};

export default Detail
