import * as React from "react";
import { SVGProps } from "react";
const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x={1.131}
      width={20}
      height={1.6}
      rx={0.8}
      transform="rotate(45 1.131 0)"
      fill="#FFFFEF"
    />
    <rect
      x={0.331}
      y={14.142}
      width={20}
      height={1.6}
      rx={0.8}
      transform="rotate(-45 .331 14.142)"
      fill="#FFFFEF"
    />
  </svg>
);
export default Close;
