import { Box } from "components/elements";
import { Heading } from "components/elements/Heading";
import TextEffect from "components/elements/TextEffect";
import { Discord, Github, LinkedIn, Medium, Telegram, Twitter, YouTube } from "components/Icons";
import useViewport from "hooks/useViewport";
import React, { useContext, useLayoutEffect, useRef } from "react";
import { styled } from "theme";
import { isSizeLG } from "utils/mediaQueryParser";
import { gsap } from "gsap";
import toNumber from "utils/numbers";
import { LoadContext } from "context/LoadContext";

const SocialLinks = React.forwardRef<HTMLDivElement>((props,ref) => {
    const {width} = useViewport()
    const textRef= useRef<HTMLDivElement>(null)
    const linksRef= useRef<HTMLDivElement>(null)
    const {loading} = useContext(LoadContext)

    const openTab = (url : string) => {
        window.open(url, '_blank', 'noopener,noreferrer');
      };

    useLayoutEffect(() => {
        let ctx : gsap.Context
        
        if(!loading){
            ctx = gsap.context(() => {

                let tl =  gsap.timeline({
                  scrollTrigger: {
                    trigger: `.anim3`,
                    pin: false,
                    scrub: true,
                    start: 'top bottom',
                    end: `+=${textRef.current && linksRef.current && textRef.current?.getBoundingClientRect().height + toNumber(getComputedStyle(linksRef.current).marginTop) + (2 * toNumber(getComputedStyle(linksRef.current).height))}`,
                    markers: false,
                    once:true,
                    toggleActions:"restart pause reverse none",
                  }
                })
                tl.fromTo(`#footerText3`, {
                  'scale': 100,
                   attr: {scale: 100},
                 },{
                     duration: 10,
                   ease: "none",
                   'scale': 0,
                    attr: {scale: 0},
                 });
              })
        }

            return () => ctx && ctx.revert(); // <- cleanup!
    },[loading])
    return (
        <Box css={{textAlign:"start",position:"relative","@lg":{
            textAlign:"end"
        }}}>
            {/* <Box as="img" src="assets/images/glowcommunityleft.png"
                css={{ $$color: "$colors$primary3",position:"absolute",right:"-58%",$$blur:"64px",top:"-140%" ,width:"257px",height:"240px","@md":{
                    left:"-140%",
                    top:"-140%" ,
                    width:"515px",
                    height:"480px"
                }}}
            /> */}
        {/* <GradientShadow css={{ $$color: "$colors$primary3",right:"-58%",$$blur:"64px",top:"-140%" ,opacity:"0.2",width:"257px",height:"240px","@md":{
            left:"-140%",
            top:"-140%" ,
            width:"515px",
            height:"480px"
        }}} /> */}
        
         {/* <GradientShadow css={{display:"none","@xl":{display:"block"}, $$color: "$colors$primary3",left:"-15%",top:"-149%" ,opacity:"0.2",width:"515px",height:"480px"}} /> */}
         {/* <Box css={{position:"absolute",left:"-43%",top:"-226%",zIndex:-9999}} as="img" src="videos/Pool.gif"/> */}
        {/* <Box> */}
                {/* <Box css={{position:"absolute",left:"-35%",top:"-174%",  background:`url('videos/Pool.gif')`,opacity:"0.2",backgroundBlendMode:"multiply",height:"977px",width:"977px", backgroundPosition:"center",backgroundRepeat:"no-repeat"}}></Box>  */}
        <Box css={{position:"relative"}}>
        <Box as="img" src={width > isSizeLG() ? "assets/images/glowcommunityleft.png" : "assets/images/glowcommunityresponsive.png" }
        alt=""
        css={{width:"initial",height:"initial",
        position:"absolute", 
        $$color: "$colors$primary3",
        transform:"translate(75%,-81%)",
        right:"25%",
        top:"19%",
        border:"1px solid transparent",
        zIndex:-1,
        "@sm":{
            transform: "translate(80%, -65%)",
            right: "20%",
            top: "35%",
        },
        "@lg":{
            display:"block",
            left: "26%",
            top:"47%",
            right:"initial",
            transform:"translate(-74%,-53%)",
            width:"1097px",
            height:"992px",
        },}} 
        />
        <LiquidVideoWrapper/>
        </Box>
         {/* <Box css={{position:"absolute",left:"0%",top:"-2%",backgroundBlendMode:"multiply",height:"300px",width:"300px", backgroundPosition:"center",backgroundRepeat:"no-repeat" }} as="img" src="videos/poolOld.gif"/>
            <Box css={{background:"$primary3",height:"300px",width:"300px"}}>

            </Box>
        </Box> */}
        <Heading ref={textRef}  className="footerText3 anim3" tag={"h2"} css={{color:"$colors$primary3",fontSize:"$2xl", lineHeight:"$2xl"}}>Join the Devil’s Revolution</Heading>
        <TextEffect url=":footerText3:" id="footerText3"/>

        <SocialIconWrapper ref={linksRef}>
            <Twitter onClick={() => openTab('https://twitter.com/liqui_devil')}/>
            <Discord onClick={() => openTab('https://discord.gg/e2umWppn4R')}/>
            <Telegram onClick={() => openTab('https://t.me/liqui_devil')}/>
            <Github onClick={() => openTab('https://github.com/liqui-devil')}/>
            {/* <YouTube onClick={() => openTab('')}/> */}
            <Medium onClick={() => openTab('https://www.blog.liquidevil.xyz/')}/>
            <LinkedIn onClick={() => openTab('https://www.linkedin.com/company/liquidevil/')}/>
         </SocialIconWrapper>
      </Box>
    );
});

export default SocialLinks
const LiquidVideoWrapper = styled('div',{
    height:"490px",
    width:"490px",
    zIndex:-2,
    position:"absolute",
    left:"75%",
    top:"38%",  
    background:`url('videos/poolOld.gif'), #000E10`,
    backgroundBlendMode:"multiply",
    backgroundPosition:"center",
    backgroundSize:"contain",
    backgroundRepeat:"no-repeat",
    border:"1px solid transparent",
    transform:"translate(-25%, -52%)",
    "@sm":{
        transform: "translate(-20%, -43%)",
        left: "80%",
        top: "57%",
    },
    "@lg":{
        transform:"translate(-75%, -37%)",
        height:"977px",
        width:"977px",
        left:"25%",
        top:"63%",  
    }
})

const SocialIconWrapper = styled('div',{
    display:"flex",
    justifyContent:"start",
    alignItems:"center",
    marginTop:"18px",
    "@sm":{
        marginTop:"20px",
    },
    "@lg":{
        justifyContent:"flex-end",
 
    },
    svg:{
        width:"20px",
        height:"20px",

        margin: "0 10px 0 10px",
   
        "&:first-child":{
            marginLeft:"0px !important"
        },
        "@sm":{
            width:"40px",
            height:"40px",
            margin: "0 20px 0 20px",
        },
        "@md":{
            width:"40px",
            height:"40px"

        },
        "@xl":{
            width:"initial",
            margin:"0 $space$10",

        },
        fill: "$colors$emphasizedText",
        "&:last-child":{
            marginRight:0
        },
        "&:hover":{
            cursor:"pointer",
            fill: "$colors$primary3"
        }
    }

})