import gsap from "gsap";

const animateText = (id: string,duration:number,scaleStartFrom:number,delay:number = 0) => {
    gsap.fromTo(id, {
        'scale': scaleStartFrom,
         attr: {scale: scaleStartFrom},
       },{
           duration: duration,
         ease: "none",
         delay:delay,
         'scale': 0,
          attr: {scale: 0},
       });
}
export default animateText