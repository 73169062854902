import Banner from "./components/Banner";
import Community from "./components/Community";
import Features from "./components/Features";
import Gallery from "./components/Gallery";
import Offers from "./components/Offers";
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import React, { useEffect, useState } from "react";
import { Box, Container } from "components/elements";
import { isSizeLG } from "utils/mediaQueryParser";
import useViewport from "hooks/useViewport";


gsap.registerPlugin(ScrollTrigger)

const Landing = () => {
  const ref1 = React.useRef<HTMLDivElement>(null)
  const ref2 = React.useRef<HTMLDivElement>(null)
  const ref4 = React.useRef<HTMLDivElement>(null)
  const refFeatureWeb = React.useRef<HTMLDivElement>(null)
  const [offerTop,setOfferTop] = useState<any>(undefined)
  const [featuresTop,setFeaturesTop] = useState<any>(undefined)
  const [svgTop,setSvgTop] = useState<any>(undefined)
   const svgRef = React.useRef<HTMLDivElement>(null)
  const {width} = useViewport()
  useEffect(() => {
    const onScroll = () => {

      if(!offerTop && ref2.current && ref1.current){
        setOfferTop(-(ref2.current.getBoundingClientRect().height - ref1.current.getBoundingClientRect().height))
      }
      if(!featuresTop && !svgTop && refFeatureWeb.current && ref1.current){
        setSvgTop(-(refFeatureWeb.current.getBoundingClientRect().height - ref1.current.getBoundingClientRect().height))
        setFeaturesTop(-(refFeatureWeb.current.getBoundingClientRect().height - ref1.current.getBoundingClientRect().height))

      }

    };
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);


  return (
    <div>
      <Box css={{display:"block", "@xl":{
        display:"block"
      }}}>
        <div ref={ref1}   style={{ zIndex: 2,position:"sticky",top:0}}>
            <Banner />
        </div>
        <div ref={ref2} className="landingBg"style={{ position:"sticky",top:offerTop ? offerTop : '', zIndex: 5 }}>
          <Gallery />
          <Offers />
        </div>
        {/* <div ref={refn} className="fixedDiv" style={{ display: "none" }} >
          <Features />
        </div> */}

        <div className="section3" ref={refFeatureWeb} style={{position:"sticky",top:featuresTop ? featuresTop : '',zIndex:6, overflowX: width > isSizeLG() ? "hidden": "initial",backgroundColor:'#000E10'}}>
        <Box ref={svgRef} css={{"@lg":{display:"none"},position:"sticky",top:0,zIndex:11}}>
              <Box css={{position:"relative"}}>
              <Box className="fadeEffect" css={{ position: "absolute", top: 0,right:-109, zIndex: 9999,width:"393px",height:"460px",
              "@md":{
                right:0,
                height:"554px",
                width:"350px"
              }
              }}>
                <img style={{width:"100%",height:"100%"}} src="assets/icons/MaskLeft.svg" alt=""  />
                  {/* <MaskLeft width={393} height={460} className="fadeEffect" /> */}
                </Box>
                <Box  className="fadeEffectReverse" css={{ position: "absolute", left: "-142px", top: "371px", zIndex: -9999 ,width:"487px",height:"416px",
                "@md":{
                  top:"280px",
                  left:"-175px",
                  height:"initial",
                  width:"initial"
                }
              }}
               >
                <img style={{width:"100%",height:"100%"}} src={'assets/icons/TailLeft.svg'} alt=""  />

                  {/* <TailLeft  width={487} height={416}  className="fadeEffectReverse" /> */}
                </Box>  
              </Box>
          </Box>
          <Features />
          <Container>
          </Container>
        </div>
        <div ref={ref4}  className="landingBg" style={{overflow:"hidden"}} >
          <Community />
        </div> 
      </Box>
     </div>
   );
};

export default Landing;