 import { css, keyframes, styled } from "theme";
 
const overlayShow = keyframes({
    "0%": { opacity: 0 },
    "100%": { opacity: 1 },
  });
  
export const overlayStyles = css({
  position: "fixed",
  top: 0,
  left: 0,
  zIndex: 9999, // use z-index from theme
  backgroundColor: "#000",
  width: "100vw",
  height: "100vh",
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
});

export const Overlay = styled("div", overlayStyles);