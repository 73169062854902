import * as React from "react";
import { SVGProps } from "react";
const SvgCircleLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    width="192" height="192" 
    viewBox="0 0 192 192"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={96} cy={96} r={94.667} stroke="#FFFFEF" strokeWidth={2.667} />
    <g filter="url(#circleLogo_svg__a)" fill="#FFFFEF">
      <path d="M132.963 70.945c1.453 4.903 2.677 13.396-.765 24.514 0 0-1.988 7.803-10.172 11.877 0 0-6.119 2.969-6.119 9.183v8.011s-7.878 2.278-17.21 13.465h-6.195C83.248 126.808 75.37 124.53 75.37 124.53v-8.08c0-6.214-6.12-9.183-6.12-9.183-8.26-4.075-10.248-11.877-10.248-11.877-3.442-11.256-2.142-19.887-.689-24.721 0 .07-.076.138-.076.207 0 0 .459 19.058 18.662 31.419 0 0 2.677 1.864 5.507 2.417.383.069.535.138.535.138 2.066.345 3.442 1.105 3.442 1.105v-.07a.07.07 0 0 1 .077.07v-.415c.153-8.493-9.102-14.57-9.102-14.57-3.824-2.416-6.578-4.971-8.566-7.526-.077-.07-.153-.207-.23-.277-.076-.069-.153-.138-.153-.207-.153-.207-.23-.345-.382-.552-.153-.138-.23-.345-.383-.553-.153-.207-.306-.414-.382-.621-.459-.76-.841-1.52-1.224-2.21-.076-.207-.153-.414-.23-.552-.076-.276-.229-.483-.305-.76-.077-.207-.153-.483-.306-.69-.306-.898-.612-1.796-.765-2.555-.076-.276-.153-.622-.153-.898-.076-.276-.076-.552-.153-.76 0-.137 0-.276-.076-.345v-.552c.23-6.49-2.371-10.841-2.371-10.841C55.407 47.468 44.929 32.483 44.546 32c.382.345 12.543 11.6 22.334 16.365 0 0 7.495 4.627 16.673 4.903H107.8c9.179-.276 16.674-4.903 16.674-4.903 9.637-4.764 21.799-16.02 22.181-16.365-.306.483-10.861 15.468-17.362 29.002 0 0-2.524 4.35-2.371 10.84 0 0 0 1.175-.383 3.04-.994 4.211-3.977 10.771-12.773 16.157 0 0-8.872 5.8-9.102 13.949 0 .207-.076.414-.076.621v.415h.076s1.377-.76 3.442-1.105c.077 0 .077 0 .23-.069H108.564c2.907-.484 5.737-2.486 5.737-2.486 18.28-12.36 18.662-31.419 18.662-31.419Z" />
      <path d="M95.565 160c7.243 0 10.386-2.408 10.386-2.408 6.286-4.267 11.069-3.786 11.069-3.786 10.318.344 11.001-6.813 11.001-6.813.82-6.401 8.268-10.668 8.268-10.668 7.994-5.575 13.187-16.036 13.187-16.036 9.566-21.129-2.323-36.407-2.323-36.407s10.728 23.331-6.628 40.743c0 0-2.528 3.097-7.038 3.992 0 0-7.174.138-9.497 2.615 0 0-1.982 1.721-4.236 2.615 0 0-3.759 1.17-6.97 6.608 0 0-2.87 4.267-6.286 6.056 0 0-3.963 1.996-8.063 7.571 0 0-1.64 2.959-2.665 3.028h-.068c-1.025-.069-2.665-3.028-2.665-3.028-4.1-5.644-8.063-7.571-8.063-7.571-3.484-1.721-6.286-6.056-6.286-6.056-3.28-5.438-6.97-6.608-6.97-6.608-2.254-.894-4.236-2.615-4.236-2.615-2.391-2.409-9.497-2.615-9.497-2.615-4.51-.895-7.038-3.992-7.038-3.992-17.697-17.412-6.901-40.743-6.901-40.743s-11.89 15.278-2.323 36.407c0 0 5.193 10.461 13.187 16.036 0 0 7.448 4.267 8.268 10.668 0 0 .683 7.157 11 6.813 0 0 4.715-.481 11.07 3.786-.069 0 3.075 2.408 10.317 2.408Z" />
    </g>
    <defs>
      <filter
        id="circleLogo_svg__a"
        x={38}
        y={32}
        width={115.199}
        height={132}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_335_1368" />
      </filter>
    </defs>
  </svg>
);
export default SvgCircleLogo;
