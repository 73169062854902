import * as React from "react";
import { SVGProps } from "react";
const SvgDiamond = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#Diamond_svg__a)">
      <path
        d="M27.603 22.956h25.023L39.984 75.178l-12.38-52.224Zm27.08-5.05L65.66 0H42.466l12.218 17.905Zm25.284 1.224L68.6 1.913 57.95 19.13h22.017Zm-57.59 0-9.8-17.447L0 19.13h22.377Zm6.533 0h22.507L40.147 2.984 28.91 19.13Zm-3.267-1.301L38.023 0h-22.54l10.16 17.829Zm30.347 5.127L42.238 80 80 22.956H55.99Zm-31.784 0H0L37.599 79.35 24.206 22.956Z"
        fill="#FF7E4A"
      />
    </g>
    <defs>
      <filter
        id="Diamond_svg__a"
        x={0}
        y={0}
        width={80}
        height={84}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={4} />
        <feGaussianBlur stdDeviation={2} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect1_innerShadow_79_480" />
      </filter>
    </defs>
  </svg>
);
export default SvgDiamond;
