import * as React from "react";
import { SVGProps } from "react";
const SvgGithub = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="#fff"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#Github_svg__a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 .833A20.072 20.072 0 0 0 7.045 5.578a19.862 19.862 0 0 0-6.78 11.959c-.76 4.66.172 9.437 2.628 13.477a19.983 19.983 0 0 0 10.782 8.575c.993.184 1.368-.432 1.368-.956 0-.523-.02-2.041-.027-3.7-5.564 1.201-6.74-2.349-6.74-2.349-.907-2.305-2.218-2.91-2.218-2.91-1.815-1.232.136-1.21.136-1.21 2.01.142 3.066 2.052 3.066 2.052 1.782 3.04 4.68 2.16 5.82 1.647.178-1.288.698-2.164 1.27-2.66-4.444-.501-9.113-2.207-9.113-9.827a7.678 7.678 0 0 1 2.06-5.338c-.206-.5-.891-2.522.195-5.269 0 0 1.68-.533 5.501 2.039a19.087 19.087 0 0 1 10.015 0c3.818-2.572 5.494-2.039 5.494-2.039 1.09 2.74.404 4.762.199 5.269a7.661 7.661 0 0 1 2.063 5.345c0 7.636-4.68 9.319-9.13 9.81.715.618 1.354 1.827 1.354 3.684 0 2.66-.023 4.801-.023 5.456 0 .53.36 1.15 1.374.956a19.983 19.983 0 0 0 10.783-8.577 19.795 19.795 0 0 0 2.626-13.478 19.862 19.862 0 0 0-6.782-11.96A20.072 20.072 0 0 0 20.007.835H20Z"
      />
      <path d="M13.38 33.292c0 .162-.186.3-.424.303-.239.004-.434-.128-.434-.29 0-.16.185-.3.424-.303.238-.003.434.126.434.29ZM14.903 33.038c.03.162-.136.33-.374.37-.239.039-.447-.057-.477-.215-.03-.158.143-.33.374-.372.232-.043.447.056.477.217ZM11.744 33.18c-.05.155-.275.224-.5.158-.226-.066-.375-.25-.332-.408.043-.158.272-.23.5-.158.229.072.375.247.332.408ZM10.243 32.535c-.109.122-.33.089-.513-.076-.182-.165-.225-.389-.116-.507.11-.119.331-.086.52.076.189.161.225.388.11.507ZM9.174 31.428c-.123.086-.331 0-.447-.171a.328.328 0 0 1 0-.474c.122-.083.33 0 .447.168.116.168.119.392 0 .477ZM8.385 30.286a.333.333 0 0 1-.41-.093c-.13-.138-.156-.329-.057-.415.1-.085.279-.046.408.093.129.138.159.329.06.415ZM7.574 29.387c-.043.098-.202.128-.331.059-.13-.07-.225-.198-.179-.3.046-.102.202-.128.331-.06.13.07.229.202.18.3Z" />
    </g>
    <defs>
      <clipPath id="Github_svg__a">
        <path  d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgGithub;
