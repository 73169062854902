import * as React from "react";
import { SVGProps } from "react";
const SvgTwitter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="#FFFFEF"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#Twitter_svg__a)">
      <path
        d="M12.584 36.25c15.09 0 23.347-12.505 23.347-23.347 0-.351-.008-.71-.024-1.062A16.666 16.666 0 0 0 40 7.592a16.18 16.18 0 0 1-4.71 1.29 8.25 8.25 0 0 0 3.608-4.539 16.495 16.495 0 0 1-5.209 1.992 8.217 8.217 0 0 0-13.99 7.482A23.309 23.309 0 0 1 2.79 5.25 8.216 8.216 0 0 0 5.326 16.2a8.216 8.216 0 0 1-3.718-1.023v.101a8.203 8.203 0 0 0 6.585 8.047 8.155 8.155 0 0 1-3.703.14 8.222 8.222 0 0 0 7.663 5.704A16.458 16.458 0 0 1 0 32.564a23.28 23.28 0 0 0 12.584 3.686Z"
      />
    </g>
    <defs>
      <clipPath id="Twitter_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgTwitter;
