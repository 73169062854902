import { Box } from "components/elements";
import { Flex } from "components/elements/Flex";
import { Heading } from "components/elements/Heading";
import { Paragraph } from "components/elements/Paragraph";
import { BorderBox, ImageWrapper, VideoWrapper } from "./styles";
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import gsap from 'gsap'
import React from "react";
import TextEffect from "components/elements/TextEffect";
import useViewport from "hooks/useViewport";
import { isSizeMD } from "utils/mediaQueryParser";
gsap.registerPlugin(ScrollTrigger)

interface DetailProps {
    img: string;
    title: string;
    description: String[]
    id: number
    titleColor: string
    gradientTop:string
    gradientBottom: string
    borderLeftColor: string
    borderRightColor: string,
    sourceTop: string,
    sourceBottom:string,
    sourceResponsive: string

}
const Details = React.forwardRef((props:DetailProps,ref:any) => {
    const {width}= useViewport()
    const { title, img, description ,id,titleColor,gradientTop,gradientBottom,borderLeftColor,borderRightColor,sourceBottom,sourceTop,sourceResponsive} = props;    
    return (
        <>
            <Box css={{ maxWidth: "900px",marginLeft: id % 2 === 0 ? "auto" : "unset" , mb:"$space$5", 
            "@md":{
                mb:"56px"
            },
            "@lg":{
                    mb: "$space$6"
            }}}>
                <Heading className={`featureHeading${id}`} tag="h2" css={{ color: titleColor, textAlign:"left" ,"-webkit-text-stroke":"0.4px", "@md":{
                    "-webkit-text-stroke":"0.6px !important",
                },"@lg":{
                    textAlign: id % 2 === 0 ? "right !important" : "left"
                }}} >
                    {title}
                </Heading>
                <TextEffect url={`:featureHeading${id}:`} id={`featureHeading${id}`} />
            </Box>
            <Flex  css={{columnGap:"80px",flexDirection:"column", "@lg":{
                flexDirection:(id % 2 === 0) ? 'row-reverse' : "row"
            }}}>
                <Flex css={{ alignSelf: "start" }}>
                    <Flex  css={{ 
                        maxWidth: "532px",
                        "@md":{
                        maxWidth: "100%"
                    },"@lg":{
                        maxWidth: "532px"
                    }}}>
                        <BorderBox css={{$$color: titleColor}} />
                        <Box>
                            {description.map((desc, index) => (
                                <Paragraph 
                                ref={index === 0 ? (el) => el  && !ref?.current.includes(el) && ref?.current.push(el) : undefined}
                                key={index} 
                                css={{ 
                                    fontFamily: "LatoRegular", color: "$emphasizedText", 
                                    mb: index + 1 === description.length ? "none" : "$space$4" , 
                                    "@md":{
                                    mb:index + 1 === description.length ? "none" : "$space$4 !important",
                                    },
                                    "@lg":{
                                        mb:index + 1 === description.length ? "none" : "$space$5 !important",
                                    }
                                }}
                                size="sm">
                                    {desc}
                                </Paragraph>
                            ))}
                        </Box>
                    </Flex>
                </Flex>
                <Box css={{position:"relative",mt:"48px",
                "@md":{
                    mt:"80px",
                },
                "@lg":{
                    mt:"28px"
                }}}>
                    {id % 2 === 0 ?  (
                        <>
                
                            <ImageWrapper css={{display:"none",$$gradient: gradientTop, top:"-106%",width:"1288px",height:"772px",left:"-87%",
                            "@lg":{
                                display:"block",
                                transform:"translate(-70%,-60%)",
                                top:"50%",
                                left:"80%",
                            }, 
                            "@xl":{
                                transform:"translate(-60%,-65%)",
                                top:"35%",
                                left:"40%",
                            }, 
                            }}>
                                <img src={sourceTop} alt=''/>
                            </ImageWrapper>
                            <ImageWrapper css={{$$gradient:gradientBottom, top:"50%",width:"1104px",height:"730px",left:"50%",transform:"translate(-50%,-50%)",
                            "@md":{
                                top:"45%",
                                left:"40%",
                                transform:"translate(-60%,-55%)"
                            },
                            "@lg":{
                                width:"1335px",
                                height:"860px",
                                bottom:"initial",
                                right:"-28%",
                                top:"80%",
                            },
                            "@xl":{
                                bottom:"-120%",
                                left:"-61%",
                                top:"initial !important",
                                transform:"unset"
                            }}}>
                                {width > isSizeMD() ? (
                                    <img src={sourceBottom} alt='' />
                                ) : (
                                    <img src={sourceResponsive} alt='' />
                                ) }                                
                            </ImageWrapper>
                            {/* <GradientBox css={{display:"none","@md":{display:"block"}, $$gradient: gradientTop, top:"-36%",width:"776px",height:"260px",left:"-29%"}}/>
                            <GradientBox css={{$$gradient:gradientBottom, bottom:"-20%",width:"590px",height:"218px",right:"-11%","@md":{
                                width:"944px",height:"348px"
                            }}}/> */}
                        </>
                    ) : (
                        <>
                            <ImageWrapper css={{display:"none",$$gradient:gradientTop, top:"-106%",width:"1288px",height:"772px",right:"-87%",
                            "@lg": {
                                transform:"translate(-40%, -60%)",
                                display:"block",
                                top:"40%",
                                left:"60%",
                            },
                            "@xl": {
                                transform:"translate(-30%,-65%)",
                                top:"35%",
                                left:"70%",
                            }
                            }}>
                               <img src={sourceTop} alt=''/>
                            </ImageWrapper> 

                            <ImageWrapper  css={{$$gradient:gradientBottom, top:"50%",width:"1104px",height:"730px",left:"50%",transform:"translate(-50%,-50%)",
                            "@md":{
                          
                                top:"45%",
                                left:"40%",
                                transform:"translate(-60%,-55%)"
                            },
                            "@lg":{
                                width:"944px",height:"860px",
                                bottom:"initial",
                                left:"28%",
                                top:"80%",

                            }, "@xl":{
                                bottom:"-120%",
                                left:"-61%",
                                top:"initial !important",
                                transform:"unset",
                                
                            }}}>
                               {/* <img src={sourceBottom} alt='' srcSet={`${sourceResponsive}480w, ${sourceBottom} 800w`}/> */}
                               {width > isSizeMD() ? (
                                    <img src={sourceBottom} alt='' />
                                ) : (
                                    <img src={sourceResponsive} alt='' />
                                ) }    
                            </ImageWrapper> 
                         
                            {/* <GradientBox css={{display:"none","@md":{display:"block"},$$gradient:gradientTop, top:"-36%",width:"776px",height:"260px",right:"-71%"}}/>
                            <GradientBox css={{$$gradient:gradientBottom, bottom:"-20%",width:"590px",height:"218px",left:"-11%","@md":{
                                width:"944px",height:"348px"
                            }}}/> */}
                        </>
                    )}
                     <VideoWrapper css={{$$borderLeftColor : borderLeftColor, $$borderRightColor : borderRightColor}}>
                        <Box as="img" src={img} />
                    </VideoWrapper>
                </Box>
            </Flex>
        </>
    );
});

export default Details
