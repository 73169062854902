import { styled } from "theme";

export const BorderBox = styled("div",{
    borderRadius: "10px",
    backgroundColor: "$$color",
    width:"2px",
    height:"100%",
    marginRight:"$space$3",
    "@md":{
        width:"4px",
        marginRight:"$space$5"
    },
    "@lg":{
        width:"4px",
        marginRight:"$space$5"

    }
})

export const VideoWrapper = styled("div",{
    position:"relative",
    "@md":{
        paddingRight:"120px",
    },
    "@lg":{
        paddingRight:"initial"
    },
     img:{
        borderLeftStyle: 'solid',
        borderRightStyle: 'solid',
        borderLeftWidth: "$radii$1",
        borderRightWidth: "$radii$1",
        borderRadius: "5px",
        borderLeftColor: "$$borderLeftColor",
        borderRightColor: "$$borderRightColor",
        width:"calc(100% - 20px)",
        "@md":{
    
            borderLeftWidth: "20px",
            borderRightWidth: "20px",
            borderRadius: "$radii$1",
            width:"calc(100% - 40px)"
        },
        "@xl":{
            borderLeftWidth: "20px",
            borderRightWidth: "20px",
            borderRadius: "$radii$1",
            width:"calc(100% - 40px)"
        },

     }
    
})

export const GradientBox = styled('div', {
    position: "absolute",
    opacity: "0.3",
    filter: "blur(128px)",
    backgroundColor: "$$gradient",
    width: "100%",
    height: "280px",   
     
})
export const ImageWrapper = styled('div', {
    position: "absolute",
    width: "100%",
    height: "280px",   
     
})