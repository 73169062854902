import { styled } from "theme";

export const Container = styled("div", {
  flexShrink: 0,
  ml: "auto",
  mr: "auto",
  // px: "$5",
  "@xs":{
    maxWidth: "$breakpoints$xs !important",
  },
  "@sm":{
    maxWidth: "540px !important",
  },
  "@md":{
    maxWidth: "772px !important",
  },
  "@lg":{
    maxWidth: "900px !important",
  },
  "@xl":{
    maxWidth: "1140px !important",
  },
  "@xxl":{
    maxWidth: "1272px !important",
  },
  "@3xl":{
    maxWidth: "1272px !important",
  },

  variants: {
    size: {
      default:{
        maxWidth: "335px",
      },
      xs: {
        maxWidth: "$breakpoints$xs",
      },
      sm: {
        maxWidth: "$breakpoints$sm",
      },
      md: {
        maxWidth: "$breakpoints$md",
      },
      lg: {
        maxWidth: "$breakpoints$lg",
      },
      xl: {
        maxWidth: "$breakpoints$xl",
      },
      fluid: {
        maxWidth: "none",
      },

    },

  },
  
  defaultVariants: {
    size: "default",
  },
});
