import React, { useRef } from "react";
import Close from "components/Icons/Close";
import { Arrowdown, Logo, NavLogo } from "components/Icons";
import { Flex } from "components/elements/Flex";
import { Links, StyledBackdrop, StyledMobileMenu, StyledMobileMenuWrapper } from "./styles";
import { Heading } from "components/elements/Heading";
import { Box, Container } from "components/elements";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "components/elements/Accordian";
interface MobileMenuProps {
  onDismiss?: () => void;
  visible?: boolean;
}

const MobileNavbar: React.FC<MobileMenuProps> = ({ onDismiss, visible }) => {
  const menus = [
    {
      name: 'About Us',
      link: 'https://calendly.com/liquidevil '
    },
    {
      name: 'FAQ',
      link: 'https://docs.liquidevil.xyz/'
    },
    // {
    //   name: 'Careers',
    //   link: ''
    // },
    {
      name: 'Docs',
      link: 'https://docs.liquidevil.xyz/'
    },
    {
      name: 'Whitepaper',
      link: 'https://docs.liquidevil.xyz/'
    },
  ]


  const openTab = (url : string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  const ref = useRef<HTMLDivElement>(null)
  const ref2 = useRef<HTMLDivElement>(null)
  if (visible) {
    return (
      <StyledMobileMenuWrapper>
        <Container>
        <StyledBackdrop ref={ref}  />
        <StyledMobileMenu ref={ref2}  >
          <Flex justify="between" align="center" css={{  marginTop:"$space$10"}}>
            <Flex align="center">
             {/* <NavLogo className="mobile-nav-logo"/> */}
             <Box className="mobile-nav-logo" as="img" src="assets/images/LogoNew.png" alt=""/>
              <Heading css={{ml:"$space$1","-webkit-text-stroke": "0.175px"}}  tag="h6" >LiquiDevil</Heading>
            </Flex>
            <Flex css={{position:"relative"}}>
              <Close className="closeIcon"  onClick={() => {
                if(ref.current && ref2.current){
                  ref.current.classList.add('closed')
                  ref2.current.classList.add('closed')
                  setTimeout(() => {
                    //@ts-ignore
                    onDismiss()
                  },1000)
                }
              }} width="36px" />
            </Flex>
          </Flex>
          <Accordion type="single"  >
            <Flex direction="column" align="center" css={{pt:"$space$11"}}>
            {menus.map((menu, i) => (
              <AccordionItem value={`${i}`}>
                    <AccordionTrigger>
                    <Flex align="baseline" className="mobile-menu-bar" key={i} css={{ w: "100%", pb: "$space$2",pt: i === 0 ? "initial" : "$space$2",borderBottom: i === menus.length - 1 ? "none": "1px solid #293638 !important" }} justify="between">
                  <Links size="2xl" onClick={() => openTab(menu.link)}>
                  {menu.name}
                  </Links>
                  <Flex>
                  <Arrowdown />
                  </Flex>
                </Flex>
                  </AccordionTrigger>
                
                <AccordionContent>
                    coming soon
                </AccordionContent>
              </AccordionItem>
            ))}
          </Flex>
         
          </Accordion>
        
        </StyledMobileMenu>
        </Container>
      </StyledMobileMenuWrapper>
    );
  }
  return null;
};



export default MobileNavbar;
