import * as React from "react";
import { SVGProps } from "react";
const SvgLinkedIn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    fill="#FFFFEF"
    viewBox="0 0 40 40"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#LinkedIn_svg__a)">
      <path
        d="M37.04 0H2.952C1.32 0 0 1.29 0 2.883v34.226C0 38.703 1.32 40 2.953 40H37.04C38.672 40 40 38.703 40 37.117V2.883C40 1.289 38.672 0 37.04 0ZM11.866 34.086H5.93V14.992h5.937v19.094ZM8.898 12.39a3.44 3.44 0 0 1-3.445-3.438 3.44 3.44 0 0 1 3.445-3.437 3.438 3.438 0 0 1 0 6.875Zm25.188 21.695h-5.93v-9.281c0-2.211-.039-5.063-3.086-5.063-3.086 0-3.554 2.414-3.554 4.906v9.438h-5.922V14.992h5.687v2.61h.078c.79-1.5 2.727-3.086 5.61-3.086 6.008 0 7.117 3.953 7.117 9.093v10.477Z"
      />
    </g>
    <defs>
      <clipPath id="LinkedIn_svg__a">
        <path fill="#fff" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);
export default SvgLinkedIn;
