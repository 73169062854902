import React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { CSS, styled } from "theme";

const StyledAccordion = styled(AccordionPrimitive.Root, {});

type AccordionPrimitiveProps = React.ComponentPropsWithoutRef<
  typeof AccordionPrimitive.Root
>;
type AccordionProps = AccordionPrimitiveProps & { css?: CSS };

export const Accordion = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Root>,
  AccordionProps
>(({ children, type, ...rest }, forwardedRef) => (
    //@ts-ignore
  <StyledAccordion
    ref={forwardedRef}
    {...rest}
    type={type}
    {...(type === "single" ? { collapsible: true } : {})}
  >
    {children}
  </StyledAccordion>
));

Accordion.displayName = "Accordion";

const StyledItem = styled(AccordionPrimitive.Item, {
  borderBottom:"1px solid #293638",
  width:"100%",
  color:"$colors$secondaryText",
  '&[data-state="open"]':{
    borderBottom:"2px solid #293638",

  },
  "&:last-of-type": {
    borderBottom: "1px solid $colors$slate6",
  },
});

const StyledHeader = styled(AccordionPrimitive.Header, {
  all: "unset",
});

const StyledTrigger = styled(AccordionPrimitive.Trigger, {
  all: "unset",
  boxSizing: "border-box",
  userSelect: "none",
  "&::before": {
    boxSizing: "border-box",
  },
  "&::after": {
    boxSizing: "border-box",
  },

  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",

  "&:focus": {
    outline: "none",
  },

  svg: {
    transition: "transform 175ms cubic-bezier(0.65, 0, 0.35, 1)",
  },

  '&[data-state="open"]': {
    color:"$colors$primary3",

    svg: {
      transform: "rotate(180deg)",
      fill:"$colors$primary3",
    },
  },
});

type AccordionTriggerPrimitiveProps = React.ComponentProps<
  typeof AccordionPrimitive.Trigger
>;
type AccordionTriggerProps = AccordionTriggerPrimitiveProps & { css?: CSS };

export const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof StyledTrigger>,
  AccordionTriggerProps
>(({ children, ...props }, forwardedRef) => (
  <StyledHeader>
    <StyledTrigger {...props} ref={forwardedRef}>
      {children}
      {/* <ChevronDownIcon /> */}
    </StyledTrigger>
  </StyledHeader>
));

AccordionTrigger.displayName = "AccordionTrigger";

const StyledContent = styled(AccordionPrimitive.Content, {
  pb: "$2",
  fontFamily:"LatoRegular",
  fontSize:"$sm",
  lineHeight:"$sm",
  color:"$secondaryText"
});

export const AccordionItem = StyledItem;
export const AccordionContent = StyledContent;