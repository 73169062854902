import { styled } from "theme";

export const Button = styled("button", {
  $$backgroundColor: "$colors$primary3",
  $$hoverBtnSpeed: "0.4s",
  $$hoverBtnHalfSpeed: "0.25s",
  $$hoverEaseOut: "cubic-bezier(0.61, 1, 0.88, 1)",
  $$hoverEaseIn: "cubic-bezier(0.12, 0, 0.39, 0)",
  position: "relative",
  overflow: "hidden",
  maskImage: "-webkit-radial-gradient(white)",
  zIndex:999,
  borderStyle: "none",
  borderColor: "currentColor",
  display: "inline-flex",
  gap: "$2",
  alignItems: "center",
  justifyContent: "center",
  flexShrink: 0,
  borderRadius: "$2xl",
  appearance: "none",
  userSelect: "none",
  outline: "transparent solid 2px",
  outlineOffset: "2px",
  lineHeight: "$sm",
  border:"none",
  fontSize:"$sm",
  letterSpacing:"$3",
  // verticalAlign: "middle",
  whiteSpace: "nowrap",
  transitionProperty: "background-color,border-color,color,box-shadow",
  transitionDuration: "200ms",
  WebkitTapHighlightColor: "rgba(0,0,0,0)",
  // need to override this in some cases
  paddingInline: "$$btnPaddingX",
  $$padding : "$space$1 $space$5",
  padding:"$$padding ",
  // width:"164px",
  // height:"40px",
  "&:disabled": {
    boxShadow: "none",
    opacity: 0.4,
    cursor: "not-allowed",
  },

  // can be bundled with compound variants if needed
  "&:focus-visible": {
    boxShadow: "$outline",
  },

  "&:hover": {
     backgroundColor: "$$backgroundColor",
     position:"relative",
    transition: "background-color 0s linear $$hoverBtnSpeed",
 
  },
  "&:hover > .innerBtn": {
    color: "$colors$slate5",
    backgroundClip:"unset",
    background: "unset",
    "-webkit-background-clip": "unset",
    "-webkit-text-fill-color": "unset",

 },

  "&::after": {
      pointerEvents: "none",
      backgroundColor: "$$backgroundColor",
      transform: "translateY(5em)",
      content: '""',
      zIndex: -99,
      position: "absolute",
      width: "100%",
      paddingTop: "100%",
      left: 0,
      top: 0,
      right: 0,
      bottom: "auto",
      borderRadius: "50%",
      transition: "border-radius $$hoverBtnHalfSpeed $$hoverEaseIn 0s, transform $$hoverBtnSpeed $$hoverEaseIn",
  },
  "&:hover::after": {
      borderRadius: 0,
      transform: "translateY(0em)",
      transition: "border-radius $$hoverBtnHalfSpeed $$hoverEaseOut $$hoverBtnHalfSpeed, transform $$hoverBtnSpeed $$hoverEaseOut",
  },

  variants: {
    size: {
      sm: {
        fontFamily:"LatoRegular",
        fontWeight:"$bold",
        fontSize: "$sm",
        lineHeight:"$sm",
        $$btnPaddingX: "$space$12",
      },
      lg: {
        fontSize: "$lg",
        fontFamily:"LatoBold",
        lineHeight:"$lg",
        $$btnPaddingX: "$space$6",
      },
    },
    gradient:{
      g1:{
        background: "$gradient1"
      }
    },
    color: {
      primary:{
        color : "$primaryText"
      },
      brown:{
        backgroundColor:"$accent3"
      },
      teal: {
        // for outline and ghost variants
        color: "$teal600",
        borderColor: "currentColor",
        "&:hover": {
          backgroundColor: "$teal50",
        },
        "&:active": {
          backgroundColor: "$teal100",
        },
      },
    },
    variant: {
      solid: {},
      outline: {
        borderWidth: "1px",
        backgroundColor: "transparent", // bg is already transparent?
      },
      ghost: {
        backgroundColor: "transparent",
      },
    },
    auto: {
      true: {
        width: "auto",
        minWidth: "min-content",
      },
    },
  },
  defaultVariants: {
    size: "sm",
    color:"$primary"
  },
});