import { keyframes } from "@stitches/react";
import { globalCss } from "./stitches.config";

const fadeIn = keyframes({
  "0%": {
     opacity: "0.3",
   },
   "100%": {
     opacity: "1",
   },
});
const fadeInReverse = keyframes({
  "0%": {
     opacity: "1",
   },
   "100%": {
     opacity: "0.3",
   },
});

const spin = keyframes({
  "0%" : {
    transform: "rotate(0deg)",
  },
  "100%":{
    transform: "rotate(360deg)",

  }
})


export const globalStyles = globalCss({
  html: {
    overflowX:"clip"
  },
  "*, *::after, *::before": {
    boxSizing: "inherit",
  },
  "*":{
    margin:0,
  },
  body: {
    fontFamily: "$sans",
    backgroundColor: "$bgColor",
    color:"$emphasizedText",
    padding: 0,
    margin:0,
    overflowX:"clip",
    "-webkit-scroll-behavior":"smooth"
    // overflowX:"hidden"
  },
  video: {
    maxWidth: "100%",
  },
  ".shadowText":{
    background: "rgb(201 198 198)",
  "-webkit-background-clip": "text",
  "-moz-background-clip": "text",
  backgroundClip: "text",
  "-webkit-text-stroke": "0.4px #ffffef",
  color: "transparent",
  textShadow: "0px 0px 2px #ffffef",
  "@md":{
    "-webkit-text-stroke": "0.6px #ffffef",

  },
  "@lg":{
    "-webkit-text-stroke": "0.7px #ffffef",

  }
},
".bottomFixed":{
  position:"fixed !important",
  bottom:"0 !important",

},
".landingBg":{
  backgroundColor:"#000E10",
  position:"relative",
},

".none":{
  display:"none",
},
".block":{
  display:"block",
},
".fixedDiv":{
  position:"fixed",
  width:"100%",
  bottom:0
},

".stickyCards":{
  position:"sticky",
  top:0,
  background:"#000E10",

},
  ".fadeEffect":{
    animation:`${fadeIn} 1800ms ease-out  alternate infinite `

  },
  ".fadeEffectReverse":{
    animation:`${fadeInReverse} 1800ms ease-out  alternate infinite `

  },
  /* Hide the scrollbar in webkit-based browsers */
".stickyCards::-webkit-scrollbar": {
  display: "none"
},

".liquidEffect":{
  filter: "url(#:R1dq6:)", 
},
".bannerLogo":{
  filter:"url(#:bannerLogo:)"
},
".bannerText":{
  filter:"url(#:bannerText:)"
},
".text1":{
  filter:"url(#:text1:)"
},
".featureHeading1":{
  filter:"url(#:featureHeading1:)"
},
".featureHeading2":{
  filter:"url(#:featureHeading2:)"
},
".featureHeading3":{
  filter:"url(#:featureHeading3:)"
},
".footerText1":{
  filter:"url(#:footerText1:)"
},
".footerText2":{
  filter:"url(#:footerText2:)"
},
".footerText3":{
  filter:"url(#:footerText3:)"
},
".section3":{
  position:"relative",
  zIndex: 6
},
".section4":{
  position:"relative",
  // zIndex: 7,
  "@xl":{
    zIndex:999
  }
},
".mobile-nav-logo":{
  width:"21px",
  height:"24px",
  "@sm":{
    width:"29px",
    height:"33px"
  }
},
".web-nav-logo":{
  width:"36px",
  height:"40px"
},
".mobile-nav-hamburger":{
  width:"24px",
  height:"24px",
  "@sm":{
    width:"29px",
    height:"29px"
  }
},
".footerlogo":{
  display:"flex",
  width:"18px",
  "@md":{
    display:"flex",
    width:"initial",
    height:"initial",
  },
  "@lg":{
    display:"block",
  },

},

//slider global styles

".swiper-slide-prev":{
  width: "184px !important",
  height: "184px !important",
  clipPath: "polygon(0 0, 100% 2%, 100% 98%, 0% 100%)"
},
// ".xxxlleft + div": {
//   width: "208px !important",
//   height: "208px !important",
//   clipPath: "polygon(0 0, 100% 4%, 100% 96%, 0% 100%)"
// },
".swiper-slide-next":{
  width: "184px !important",
  height: "184px !important",
  clipPath: "polygon(0 2%, 100% 0%, 100% 100%, 0% 98%)"
} ,
".stylel":{
  minWidth: "172px ",
  height: "172px ",
  "@lg":{
    minWidth: "112px ",
    height: "112px ",
  },
  "@xl":{
    minWidth: "152px ",
    height: "152px ",
  },
  "@xxl":{
    minWidth: "172px ",
    height: "172px ",
  },
  "@3xl":{
    minWidth: "172px ",
    height: "172px ",
  },
 },
" .stylexl":{
  minWidth: "184px ",
    height: "184px ",
  "@lg":{
    minWidth: "124px ",
    height: "124px ",
  },
  "@xl":{
    minWidth: "164px ",
    height: "164px ",
  },
  "@xxl":{
    minWidth: "184px ",
    height: "184px ",
  },
    "@3xl":{
    minWidth: "184px ",
    height: "184px ",
  },



 },
" .stylexxl":{
  minWidth: "208px ",
      height: "208px ",
    "@lg":{
      minWidth: "148px ",
      height: "148px ",
    },
    "@xl":{
      minWidth: "188px ",
      height: "188px ",
    },
    "@xxl":{
      minWidth: "208px ",
      height: "208px ",
    },
    "@3xl":{
      minWidth: "208px ",
      height: "208px ",
    },
  


 },
 ".stylexxxl":{
  height: "232px ",
    minWidth:"232px  ",
  "@lg":{
    minWidth: "172px ",
    height: "172px ",
  },
  "@xl":{
    minWidth: "212px ",
    height: "212px ",
  },
  "@xxl":{
    minWidth: "232px ",
    height: "232px ",
  },
  "@3xl":{
    height: "232px ",
    minWidth:"232px  ",
  },


 },
 ".xlleft":{
   clipPath:" polygon(0 0, 100% 2%, 100% 98%, 0% 100%)",
 },
".xlright":{
  clipPath:" polygon(0 2%, 100% 0%, 100% 100%, 0% 98%)",
},

".xxlleft":{
  clipPath: "polygon(0 0, 100% 4%, 100% 96%, 0% 100%)",
},
".xxlright":{
 clipPath: "polygon(0 4%, 100% 0%, 100% 100%, 0% 96%)",
},
".xxxlleft":{
  clipPath: "polygon(48% 0, 100% 3%, 100% 97%, 48% 100%)",
  margin: "0 6px 0 0 !important"

},
".xxxlright":{
  clipPath: "polygon(0 3%, 52% 0, 52% 100%, 0 97%)",
  margin: "0 0 0 6px !important"
},
 ".swiper-slide-active":{
  width: "172px !important",
  height: "172px !important",
 },
 ".featuresec":{
  position:"fixed !important",
  bottom:0,
  width:"100%",
  zIndex:"99 !important",
  transform:"translateY(0) !important",

},
".gallerysec":{
  position:"fixed !important",
  bottom:0,
  transform:"translateY(0) !important",
  width:"100%",
  zIndex:9999
},
"gallerynormal":{
  position:"relative !important",
  transform:"translateY(100px) !important",
  width:"100%"
},
".relative":{
  position:"relative !important",
  width:"100%"

},
".hidden":{
  display:"none"
},
".bannerCoverLogo":{
  width:"160px",
  height:"160px",
  "@md":{
    width:"176px",
    height:"176px"
  },"@lg":{
    width:"initial",
    height:"initial"
  }
},
".swiper-wrap":{
  transitionDuration:"0ms !important",
  
},
".show":{
  display:"block"
},
".hide":{
  display:"none"
},
'h3[data-state="open"]':{
  ".mobile-menu-bar":{
    borderBottom:"none !important"
  }
},
".initial-border":{
  borderRadius: "24px",
  padding: "2px",
  position: "relative",
  zIndex: 1,
  backgroundImage: "linear-gradient(0deg, #ff7e4a, #75fb91, #00f0ff)"
},
".animate-border":{
  padding: "2px",
  borderRadius: "24px",
  overflow: "hidden",
  position:"sticky",
  willChange:"transform",
  "&:before":{
    content: "",
    height: "200px",
    width: "200px",
    position: "absolute",
    background: "linear-gradient(0deg , rgb(255, 126, 74), rgb(117, 251, 145), rgb(0, 240, 255) )",
    left:"-8%",
    "@lg":{
      left:"-4%"
    },
    transform:"translate(-50%,-50%)",
    transformOrigin:"center",
    // top: "-133%",
    top:"-158%",
    animation: `${spin} 1.5s infinite linear`,
  },
  "&:hover::before":{
    backgroundImage: "linear-gradient(#ffffef,#ffffef)"

  }


}
});
