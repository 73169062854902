import { Box, Button, Container } from "components/elements";
import { Flex } from "components/elements/Flex";
import {  Menu, NavLogo } from "components/Icons";
import React, { useContext, useEffect, useState } from "react";
import { MobileNavbarIcon, NavbarFixedTop } from "./styles";
import { Heading } from "components/elements/Heading";
import { LoadContext } from "context/LoadContext";

interface NavbarProps {
  onPresentMobileMenu: () => void;
}

const Navbar: React.FC<NavbarProps> = ({ onPresentMobileMenu }) => {
  const [animationTriggered,setAnimationTriggered]= useState(false)
  const {loading} = useContext(LoadContext)

  useEffect(() => {
    let timeoutId : ReturnType<typeof setTimeout>

    if(!loading){
      timeoutId = setTimeout(() => {
        setAnimationTriggered(true)
      },1500)
    }
    return () => clearTimeout(timeoutId)
  },[loading])

   return (
    <NavbarFixedTop>
      <Container>
        <Flex className="mob-view">
            <Flex align="center" className="mobile-box1" css={{visibility:"hidden"}}>
             {/* <NavLogo  /> */}
             <Box className="mobile-nav-logo" as="img" src="assets/images/LogoNew.png" alt=""/>
              <Heading css={{ml:"$space$1","-webkit-text-stroke": "0.175px"}}  tag="h6" >LiquiDevil</Heading>
            </Flex>
            <MobileNavbarIcon css={{visibility:"hidden"}} className="mobile-box2" onClick={onPresentMobileMenu}>
              <Menu  className="mobile-nav-hamburger"/>
            </MobileNavbarIcon>
        </Flex>
        <Flex className="web-view" justify="between"  align="center"> 
            <Flex align="center" css={{visibility:"hidden"}} className="box1">
            {/* <NavLogo/> */}
            <Box className="web-nav-logo" as="img" src="assets/images/LogoNew.png" alt=""/>
            <Heading css={{ml:"$space$3","-webkit-text-stroke": "0.23px"}} tag={"h4"} >LiquiDevil</Heading>
            </Flex>
            {/* <Box 
            className="animate-border" css={{visibility:"hidden"}}>
              <Button css={{
                backgroundColor: "$colors$slate5",
                borderRadius: "24px",
                $$backgroundColor:"$colors$emphasizedText",
                }}>
                  <Box as="span" className="innerBtn" css={{
                    display:"inline-block",
                    background: "$colors$gradient6",
                    "-webkit-background-clip": "text",
                    "-webkit-text-fill-color": "transparent",
                    backgroundClip: "text",
                    lineHeight:"$lg",
                    fontSize:"$lg",
                    fontFamily:"LatoBold",
                    $$backgroundColor:"$colors$emphasizedText",
                  }}>
                 Launch App
                  </Box>
                </Button>              
            </Box> */}


            {animationTriggered ? (
              <Box className="box2" css={{borderRadius:"24px",visibility:"hidden"}}>
              <Box 
                className="animate-border" css={{position:"relative"}}>
                  <Button css={{
                    backgroundColor: "$colors$slate5",
                    borderRadius: "24px",
                    $$backgroundColor:"$colors$emphasizedText",
                    }}>
                      <Box as="span" className="innerBtn" css={{
                        display:"inline-block",
                        background: "$colors$gradient6",
                        "-webkit-background-clip": "text",
                        "-webkit-text-fill-color": "transparent",
                        backgroundClip: "text",
                        lineHeight:"$lg",
                        fontSize:"$lg",
                        fontFamily:"LatoBold",
                        $$backgroundColor:"$colors$emphasizedText",
                      }}>
                      Launch App
                      </Box>
                    </Button>              
                </Box>
              </Box>
            ) : (
              <Box 
            className="initial-border box2" css={{visibility:"hidden"}}>
              <Button css={{
                backgroundColor: "$colors$slate5",
                borderRadius: "24px",
                $$backgroundColor:"$colors$emphasizedText",
                }}>
                  <Box as="span" className="innerBtn" css={{
                    display:"inline-block",
                    background: "$colors$gradient6",
                    "-webkit-background-clip": "text",
                    "-webkit-text-fill-color": "transparent",
                    backgroundClip: "text",
                    lineHeight:"$lg",
                    fontSize:"$lg",
                    fontFamily:"LatoBold",
                    $$backgroundColor:"$colors$emphasizedText",
                  }}>
                 Launch App
                  </Box>
                </Button>              
            </Box>
            )}
       
          </Flex>
      </Container>
    </NavbarFixedTop>
  );
};

export default Navbar;
