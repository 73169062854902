import type * as Stitches from "@stitches/react";

const defaultTokens = {
  fonts: {
    sans: "FreeSans, Arimo, 'Droid Sans', 'Segoe UI', Helvetica, Arial, sans-serif;",
    mono: "Menlo, Monaco, 'Lucida Console', 'Courier New', monospace;",
  },
  fontSizes: {
    xxs: "12px",
    xs: "14px",
    sm: "16px",
    md: "18px",
    lg:"20px",
    xl:"24px",
    "2xl": "32px",
    "3xl": "40px",
    "4xl":"48px",
    "5xl":"64px",
    "6xl":"88px",
  },
  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
  },
  lineHeights: {
    xxs: "18px",
    xs: "20px",
    sm: "24px",
    md: "28px",
    lg:"30px",
    xl:"36px",
    "2xl": "40px",
    "3xl": "48px",
    "4xl":"58px",
    "5xl":"76px",
    "6xl":"106px",
    normal: "normal",
    none: "1",
    shorter: "1.25",
    short: "1.375",
    base: "1.5",
    tall: "1.625",
    taller: "2",
  },
  letterSpacings: {
    1:"0.04em",
    2:"0.08em",
    3:"0.1em",
    4:"0.02em",
    lg: "8%",
    xxl:"4%"
  },
  space: {
    0: "0rem",
    xs: "0.5rem",
    sm: "0.75rem", // used in container gap unit
    md: "1rem",
    lg: "1.375rem", // used in row gap unit
    xl: "2.25rem",
    "2xl":"3rem",
    "3xl":"4rem",
    1: "8px",
    2: "12px",
    3: "16px",
    4: "24px",
    5:"32px",
    6:"48px",
    7: "60px",
    8:"64px",
    9:"80px",
    10:"20px",
    11:"40px",
    12:"56px",
    13:"92px",
    14:"88px",
    15:"58px",
    16:"118px",
    17:"148px",
    18:"164px",
    19:"120px",
    20:"84px",
    21:"108px",
    22:"156px",
    23:"144px",
    "100":"100%",

  },
  sizes: {
    8: "2rem",
  },
  breakpoints: {
    xs: "335px",
    sm:"576px",
    md:"768px",
    lg:"992px",
    xl:"1200px",
    xxl:"1400px",
    "3xl":"1512px"
  },
  radii: {
    none:0,
    xs: "7px",
    sm: "9px",
    md: "12px",
    base: "14px",
    lg: "14px",
    xl: "18px",
    "2xl":"24px",
    squared: "33%",
    rounded: "50%",
    pill: "9999px",
    1:"10px",
    2:"20px"
  },
};

const defaultColors = {
  // generic colors
  white: "#ffffff",
  black: "#000000",
  bgColor: "$slate5",

  // brand colors
  primary1 : "$green1",
  primary2 : "$green2",
  primary3 : "$green3",
  primary4 : "$green4",
  primary5 : "$green5",

  //secondary
  secondary1 : "$blue1",
  secondary2 : "$blue2",
  secondary3 : "$blue3",
  secondary4 : "$blue4",
  secondary5 : "$blue5",

  //accent
  accent1 : "$brown1",
  accent2 : "$brown2",
  accent3 : "$brown3",
  accent4 : "$brown4",
  accent5 : "$brown5",

  //alerts
  alert1 : "$pink1",
  alert2 : "$pink2",
  alert3 : "$pink3",
  alert4 : "$pink4",
  alert5 : "$pink5",

  //text colors
  emphasizedText : "$slate1",
  defaultText : "$slate2",
  secondaryText : "$slate3",
  tertiaryText : "$slate4",
  primaryText : "$slate5",
  gradient:
    "linear-gradient(112deg, $cyan600 -63.59%, $pink600 -20.3%, $blue600 70.46%)",
};

const defaultMedia = {
  xs_max: `(max-width: ${defaultTokens.breakpoints.xs})`,
  xs: `(min-width: ${defaultTokens.breakpoints.xs})`,
  sm: `(min-width: ${defaultTokens.breakpoints.sm})`,
  md: `(min-width: ${defaultTokens.breakpoints.md})`,
  lg: `(min-width: ${defaultTokens.breakpoints.lg})`,
  xl: `(min-width: ${defaultTokens.breakpoints.xl})`,
  xxl: `(min-width: ${defaultTokens.breakpoints.xxl})`,
  "3xl": `(min-width: ${defaultTokens.breakpoints["3xl"]})`,
  motion: "(prefers-reduced-motion: reduce)",
  dark: "(prefers-color-scheme: dark)",
  light: "(prefers-color-scheme: light)",
};

const defaultUtils = {
  p: (value: Stitches.PropertyValue<"padding">) => ({
    padding: value,
  }),
  pt: (value: Stitches.PropertyValue<"paddingTop">) => ({
    paddingTop: value,
  }),
  pr: (value: Stitches.PropertyValue<"paddingRight">) => ({
    paddingRight: value,
  }),
  pb: (value: Stitches.PropertyValue<"paddingBottom">) => ({
    paddingBottom: value,
  }),
  pl: (value: Stitches.PropertyValue<"paddingLeft">) => ({
    paddingLeft: value,
  }),
  px: (value: Stitches.PropertyValue<"paddingLeft">) => ({
    paddingLeft: value,
    paddingRight: value,
  }),
  py: (value: Stitches.PropertyValue<"paddingTop">) => ({
    paddingTop: value,
    paddingBottom: value,
  }),

  m: (value: Stitches.PropertyValue<"margin">) => ({
    margin: value,
  }),
  mt: (value: Stitches.PropertyValue<"marginTop">) => ({
    marginTop: value,
  }),
  mr: (value: Stitches.PropertyValue<"marginRight">) => ({
    marginRight: value,
  }),
  mb: (value: Stitches.PropertyValue<"marginBottom">) => ({
    marginBottom: value,
  }),
  ml: (value: Stitches.PropertyValue<"marginLeft">) => ({
    marginLeft: value,
  }),
  mx: (value: Stitches.PropertyValue<"marginLeft">) => ({
    marginLeft: value,
    marginRight: value,
  }),
  my: (value: Stitches.PropertyValue<"marginTop">) => ({
    marginTop: value,
    marginBottom: value,
  }),

  bc: (value: Stitches.PropertyValue<"backgroundColor">) => ({
    backgroundColor: value,
  }),
  bi: (value: Stitches.PropertyValue<"backgroundImage">) => ({
    backgroundImage: value,
  }),
  bgClip: (value: Stitches.PropertyValue<"backgroundClip">) => ({
    WebkitBackgroundClip: value,
    backgroundClip: value,
  }),
  bs: (value: Stitches.PropertyValue<"boxShadow">) => ({ boxShadow: value }),
  bgsize: (value: Stitches.PropertyValue<"backgroundSize">) => ({ backgroundSize: value }),
  bgposition: (value: Stitches.PropertyValue<"backgroundSize">) => ({ backgroundSize: value }),

  dflex: (value: Stitches.PropertyValue<"alignItems">) => ({
    display: "flex",
    alignItems: value,
    justifyContent: value,
  }),
  fd: (value: Stitches.PropertyValue<"flexDirection">) => ({
    flexDirection: value,
  }),
  fw: (value: Stitches.PropertyValue<"flexWrap">) => ({ flexWrap: value }),
  fg: (value: Stitches.PropertyValue<"flexGrow">) => ({ flexGrow: value }),
  fs: (value: Stitches.PropertyValue<"flexShrink">) => ({
    flexShrink: value,
  }),
  fb: (value: Stitches.PropertyValue<"flexBasis">) => ({ flexBasis: value }),

  ai: (value: Stitches.PropertyValue<"alignItems">) => ({
    alignItems: value,
  }),
  ac: (value: Stitches.PropertyValue<"alignContent">) => ({
    alignContent: value,
  }),
  ji: (value: Stitches.PropertyValue<"justifyItems">) => ({
    justifyItems: value,
  }),
  jc: (value: Stitches.PropertyValue<"justifyContent">) => ({
    justifyContent: value,
  }),
  as: (value: Stitches.PropertyValue<"alignSelf">) => ({ alignSelf: value }),

  w: (value: Stitches.PropertyValue<"width">) => ({ width: value }),
  h: (value: Stitches.PropertyValue<"height">) => ({ height: value }),
  size: (value: Stitches.PropertyValue<"width">) => ({
    width: value,
    height: value,
  }),
  minSize: (value: Stitches.PropertyValue<"minWidth">) => ({
    minWidth: value,
    minHeight: value,
  }),
  maxSize: (value: Stitches.PropertyValue<"maxWidth">) => ({
    maxWidth: value,
    maxWeight: value,
  }),
  ox: (value: Stitches.PropertyValue<"overflowX">) => ({ overflowX: value }),
  oy: (value: Stitches.PropertyValue<"overflowY">) => ({ overflowY: value }),

  br: (value: Stitches.PropertyValue<"borderRadius">) => ({
    borderRadius: value,
  }),
  btrr: (value: Stitches.PropertyValue<"borderTopRightRadius">) => ({
    borderTopRightRadius: value,
  }),
  bbrr: (value: Stitches.PropertyValue<"borderBottomRightRadius">) => ({
    borderBottomRightRadius: value,
  }),
  bblr: (value: Stitches.PropertyValue<"borderBottomLeftRadius">) => ({
    borderBottomLeftRadius: value,
  }),
  btlr: (value: Stitches.PropertyValue<"borderTopLeftRadius">) => ({
    borderTopLeftRadius: value,
  }),

  ta: (value: Stitches.PropertyValue<"textAlign">) => ({ textAlign: value }),
  tt: (value: Stitches.PropertyValue<"textTransform">) => ({
    textTransform: value,
  }),
  lh: (value: Stitches.PropertyValue<"lineHeight">) => ({
    lineHeight: value,
  }),

  pe: (value: Stitches.PropertyValue<"pointerEvents">) => ({
    pointerEvents: value,
  }),
  us: (value: Stitches.PropertyValue<"userSelect">) => ({
    WebkitUserSelect: value,
    userSelect: value,
  }),
  appearance: (value: Stitches.PropertyValue<"appearance">) => ({
    WebkitAppearance: value,
    appearance: value,
  }),
  linearGradient: (value: Stitches.PropertyValue<"backgroundImage">) => ({
    backgroundImage: `linear-gradient(${value})`,
  }),
  textGradient: (value: Stitches.PropertyValue<"backgroundImage">) => ({
    backgroundImage: `linear-gradient(${value})`,
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: "transparent",
    "&::selection": {
      WebkitTextFillColor: "$colors$text",
    },
  }),
};

// const  defaultThemeMap = {};

const commonTheme = {
  theme: {
    ...defaultTokens,
    colors: defaultColors,
    shadows: {},
  },
  media: defaultMedia,
  utils: defaultUtils,
  // themeMap: defaultThemeMap,
};

export default commonTheme;
