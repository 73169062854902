import * as React from "react";
import { SVGProps } from "react";
const SvgStocks = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={80}
    height={80}
    viewBox="0 0 84 84"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M2 82c18-6 68.5-35 80-80"
      stroke="#75FB91"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M73.338 10.662 82 2v11.72"
      stroke="#75FB91"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M61.618 9.643c-19.975.816-29.724 16.985-32.102 24.968l9.172 8.153c19.567-8.968 23.44-25.817 22.93-33.12Z"
      stroke="#75FB91"
      strokeWidth={2.5}
      strokeLinejoin="round"
    />
    <path
      d="m31.045 30.535-6.625 1.529c.17-3.058 2.956-9.58 12.74-11.21M43.211 40.914l-1.036 6.719c3.037-.395 9.336-3.654 10.24-13.531"
      stroke="#75FB91"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <circle
      cx={47.86}
      cy={24.42}
      r={4.105}
      stroke="#75FB91"
      strokeWidth={3}
      strokeLinejoin="round"
    />
    <path
      d="M27.478 76.904c10.36-5.775 34.751-23.44 49.426-47.898V81.49h-11.72V46.331M26.459 39.707l3.567-4.076m-1.53 9.681 5.606-6.114m.51 7.643 3.566-4.077"
      stroke="#75FB91"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m39.197 31.554 2.548 2.548"
      stroke="#75FB91"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.478 77.414v4.076h11.72V69.77M45.822 64.675V81.49H58.05V53.975"
      stroke="#75FB91"
      strokeWidth={2.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default SvgStocks;
