import * as React from "react";
import { SVGProps } from "react";
const SvgArrowdown = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={12}
    fill="#B0B4B5"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.81 10.876a1 1 0 0 1-1.62 0L.483 1.586A1 1 0 0 1 1.294 0h13.411a1 1 0 0 1 .811 1.585l-6.705 9.291Z"
    />
  </svg>
);
export default SvgArrowdown;
