import * as React from "react";
import { SVGProps } from "react";
const Menu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x={2.6} y={5} width={20} height={2} rx={1} fill="#FFFFEF" />
    <rect x={2.6} y={11} width={20} height={2} rx={1} fill="#FFFFEF" />
    <rect x={2.6} y={17} width={20} height={2} rx={1} fill="#FFFFEF" />
  </svg>
);
export default Menu;
