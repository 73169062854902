import { RouterProvider } from "react-router-dom";
import router from "routes";
import "assets/fonts/Lato/fonts.css"
import "assets/fonts/Amazonica/fonts.css"
import {globalStyles} from 'theme'
import { LoadContextProvider } from "context/LoadContext";
function App() {
  globalStyles()
  return (
   <LoadContextProvider >
     <RouterProvider router={router} fallbackElement={<div>loading...</div>} />
   </LoadContextProvider>
  );
}

export default App;
