import { styled } from "theme"

export const SliderWrapper = styled('div',{
    display:"flex ",
    alignItems:"center" ,
    overflow:"hidden",
    marginLeft:"-359px",
    "@lg":{
        marginLeft:"-83px",

      },
      "@xl":{
        marginLeft:"-104px",

      },
      "@xxl":{
        marginLeft:"-107px",

      },
      "@3xl":{
        marginLeft:"-110px",

      },
})
