import { Box, Container } from "components/elements";
import { MaskLeft, MaskRight, TailLeft, TailRight } from "components/Icons";
import Details from "./components/Details";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import toNumber from "utils/numbers";
import { LoadContext } from "context/LoadContext";
gsap.registerPlugin(ScrollTrigger);



const Features = React.forwardRef<HTMLDivElement>((props,ref) => {
  const revealRefs   = useRef<HTMLDivElement[]>([]);
  const paragraphRefs   = useRef<HTMLDivElement[]>([]);
  revealRefs.current = []
  paragraphRefs.current = []
  const {loading} = useContext(LoadContext)

  useEffect(() => { 
    let ctx : gsap.Context
    let height : number
   
    if(!loading){
      if(revealRefs.current.length > 0 && paragraphRefs.current.length > 0){
        height = toNumber(getComputedStyle(revealRefs.current[0].children[0]).height)
        
        ctx = gsap.context(() => {  
          revealRefs.current.forEach((panel: HTMLDivElement, i) => {
          let tl =  gsap.timeline({
            scrollTrigger: {
              trigger: `.featureHeading${i+1}`, 
              pin: false,
              scrub: true,
              start: `top bottom`,
              end:`+=${paragraphRefs.current[i] && height + toNumber(getComputedStyle(panel.children[0]).marginBottom) + toNumber(getComputedStyle(paragraphRefs.current[i]).lineHeight) }`,
              markers: false,
              once:true,
              toggleActions:"restart pause reverse none",
            }
          })
          tl.fromTo(`#featureHeading${i+1}`, {
            'scale': 100,
             attr: {scale: 100},
           },{
               duration: 10,
             ease: "none",
             'scale': 0,
              attr: {scale: 0},
           });
          });
        
      })
      }
    }
    return () =>ctx && ctx.revert();
  },[loading])

const featureDetails = [
    {
      id:1,
      title: "Wear your alchemist hat and bring out your cauldron!",
      titleColor: "$colors$primary3",
      img: "assets/images/video1.png",
      description: [
        'Create a liquidity pool and earn trading fees seamlessly',
        'Fully customize & deploy a unified bonding curve to price both floor and rare NFTs',
        ' Assign relative value to each rare trait in the collection to define a rarity premium.'
      ],
      gradientTop: "$colors$secondary3",
      gradientBottom: "$colors$primary3",
      borderLeftColor: "$colors$primary3",
      borderRightColor: "$colors$secondary3",
      sourceTop: "assets/images/glow1top.png",
      sourceBottom: "assets/images/glow1bottom.png",
      sourceResponsive:"assets/images/glowfeature1.png"
    },
    {
      id:2,
      title: "Its midnight anon, be at the Crossroads to trade!",
      img: "assets/images/video1.png",
      titleColor: "$colors$accent3",
      description: [
        ' Buy & sell both floor and rare NFTs effortlessly',
        'Most accurate price for rare NFTs based on rarity premium',
        'Experience unparalleled depth of liquidity'
      ],
      gradientTop: "$colors$primary3",
      gradientBottom: "$colors$accent3",
      borderLeftColor: "$colors$primary3",
      borderRightColor: "$colors$accent3",
      sourceTop: "assets/images/glow2top.png",
      sourceBottom: "assets/images/glow2bottom.png",
      sourceResponsive:"assets/images/glowfeature2.png"
    },
    {
      id:3,
      title: " Sell your collection's soul for liquidity and earn it back with interest!",
      img: "assets/images/video1.png",
      titleColor: "$colors$secondary3",
      description: [
        'Create a pool or provide liquidity in an existing pool to earn yield',
        'Use LP tokens across Defi Protocols',
        'Provide liquidity using ETH and/or NFTs',
        'Maximize yield on your NFT portfolio'
      ],
      gradientTop: "$colors$accent3",
      gradientBottom: "$colors$secondary3",
      borderLeftColor: "$colors$secondary3",
      borderRightColor: "$colors$accent3",
      sourceTop: "assets/images/glow3top.png",
      sourceBottom: "assets/images/glow3bottom.png",
      sourceResponsive:"assets/images/glowfeature3.png"
    }
  ]


  return (
  
    <>
       <Box css={{ position: "relative", zIndex: 11, pt: "30px",   
       "@md":{
        pt:"104px",
       },
       "@lg":{
        pt: "$space$18" ,pb:"$space$22"
       }}} >
        {/* for web */}
        <Box className="fadeEffect" css={{"@lg":{
          display:"block !important"
        } ,display:"none",position: "absolute", left: 0, top: "9%", zIndex: -9999 }}>
          {/* <MaskRight className="fadeEffect" /> */}
          <img style={{width:"100%"}} src="assets/icons/MaskRight.svg" alt=""  />

        </Box>

        <Box css={{
          display:"none !important",
          "@lg":{
            display:"block"
          }
        }} >
        <Box css={{ position: "absolute", top: 0, right: "-26%", zIndex: -9999 }}>
          <MaskLeft width={393} height={460} className="fadeEffect" />
        </Box>
        <Box css={{ position: "absolute", left: "-34.5%", top: "16.2%", zIndex: -9999 }}>
          <TailLeft  width={487} height={416}  className="fadeEffectReverse" />
        </Box>
        </Box>
       
        <Box className="fadeEffectReverse" css={{display:"none","@lg":{
          display:"block",
        }, position: "absolute", right: 0, top: "-1%", zIndex: -9999 }}>
          {/* <TailRight className="fadeEffectReverse" /> */}
          <img style={{width:"100%"}} src="assets/icons/TailRight.svg" alt=""  />

        </Box>
        {/* height:"100vh",overflowY:"scroll",overflowX:"hidden" */}
        <Container  css={{ p: "0 0 $space$6 0", "@lg":{
         p: "0 0 $space$9 0"
        } }}   >


          {featureDetails.map((feature, index) => (
              <Box 
              
              key={index}
              ref={(el) => el  && !revealRefs.current.includes(el) && revealRefs?.current.push(el)}
              css={{marginBottom: "96px", display: "flex", flexDirection: "column",
              "@md":{
                marginBottom: "180px ",

              },
              "@lg":{
                marginBottom: "216px ",
              } }}>
              <Details
                id={index + 1}
                title={feature.title}
                img={feature.img}
                titleColor={feature.titleColor}
                description={feature.description}
                gradientTop={feature.gradientTop}
                gradientBottom={feature.gradientBottom}
                borderLeftColor={feature.borderLeftColor}
                borderRightColor={feature.borderRightColor}
                sourceTop={feature.sourceTop}
                sourceBottom={feature.sourceBottom}
                sourceResponsive={feature.sourceResponsive}
                ref={paragraphRefs}
              />
            </Box>
            
          ))}


        </Container>

        <Box  className="fadeEffect" css={{ display:"none","@lg":{
          display:"block"
        }, position: "absolute", right: 0, bottom: "26%", zIndex: -9999 }}>
          {/* <MaskLeft className="fadeEffect" /> */}
          <img style={{width:"100%"}} src="assets/icons/MaskLeft.svg" alt=""  />

        </Box>
        <Box className="fadeEffectReverse" css={{ display:"none","@lg":{
          display:"block"
        },position: "absolute", left: 0, bottom: "1.5%", zIndex: -9999 }}>
          {/* <TailLeft className="fadeEffectReverse" /> */}
          <img style={{width:"100%"}} src="assets/icons/TailLeft.svg" alt=""  />

        </Box>

      </Box>

       

    </>

  );
});

export default Features;