import { Paragraph } from "components/elements/Paragraph";
import { keyframes, styled } from "theme"

const upDown = keyframes({
    "0%": {
      transform: "translateY(0)",
    },
    "100%": {
      transform: "translateY(-10px)",
    },
  });


export const FeatureDescription = styled(Paragraph,{
  fontFamily: "LatoSemiBold", 
  letterSpacing: "$letterSpacings$2", 
  mt: "$space$3",
  "@md":{
    fontFamily: "LatoRegular", 
  },
  "@lg":{
    mt: "$space$10",
    fontFamily: "LatoSemiBold", 
  }
})

export const FeatureDetails = styled('div', {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "140px auto",
    maxWidth:"250px",
    "@md":{
      maxWidth:"280px",
      margin: "100px auto",
    },
    "@lg":{
      maxWidth: "350px",
      margin: "unset"
    },
    "&:nth-child(1)":{
      marginTop:"78px",
      "@md":{
        marginTop:"100px"
      },
      "@lg":{
        marginTop:"unset"
      }
    },
    // width: "100%",
    justifyContent: "center",
    textAlign: "center",
    position: "relative",
    // margin:"32px 0",

    "[class^='float']": {
      width:"64px",
      height:"64px",
      "@lg":{
         width:"80px",
         height:"80px",
      }
    },
    ".float":{
      animation: `${upDown}   1.8s 1.2s infinite linear alternate`,
      "@md":{
        animation: `${upDown}   1.8s 0s infinite linear alternate`
      }
    },
     ".float2":{
        animation: `${upDown}   1.8s  1.2s infinite linear alternate`,
        "@md":{
          animation: `${upDown}   1.8s 1.2s infinite linear alternate`,
        }
    },
     ".float3":{
        animation: `${upDown}   1.8s 1.2s infinite linear alternate`,
        "@md":{
          animation: `${upDown}   1.8s 2.4s infinite linear alternate`
        }
    }

})

export const GradientShadow = styled('div', {
    position: "absolute",
    $$opacity:"0.4",
    opacity: "$$opacity",
    borderRadius: "50%",
    zIndex:999,
    $$blur: "102.4px",
    filter: "blur($$blur)",
    
    backgroundColor: "$$color",
    width: "240px",
    // top:"35px",
    height: "240px",
    "@lg":{
      width: "300px",
      height: "300px",
      filter: "blur(128px)",
      // "-webkit-filter": "blur(128px)",
    }

})