import { styled } from "theme";

export const NavbarFixedTop = styled('div', {
  position: 'fixed',
  width: "100%",
    // zIndex: 97,
    zIndex: 3,
    "@xl":{
      position: 'fixed',
    },

    background: "transparent",
    ".flex": {
      display: "flex",
      ".mr-66": {
        mr: 66,
        pr: 0
      },

    },
    ".web-view":{
      marginTop:"$space$4",
      display: "none",
      "@lg":{
        display:"flex",
      }
    },
    ".mob-view": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      width: "100%",
      marginTop:"$space$10",
      "@lg":{
        display:"none",
      }
    },
    ".scroll": {
      overflow: "auto",
      width: "100%",
    },
  })
  
  
  
export  const MobileNavbarIcon = styled('div', {
    display: "flex",
    cursor: 'pointer',
  });
  
  