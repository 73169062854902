import React from "react";
import { Text } from "./Text";
import { VariantProps, CSS } from "theme";
import merge from "lodash.merge";

const DEFAULT_TAG = "h2";

type TextSizeVariants = VariantProps<typeof Text>["size"];
type HeadingSizeVariants =
  | "xl"
  | "2xl"
  | "3xl"
  | "4xl"
  | "5xl"
  | "6xl";
  type HeadingTagsVariants =
  | "h1"
  | "h2"
  | "h3"
  | "h4"
  | "h5"
  | "h6"
type HeadingVariants = Omit<VariantProps<typeof Text>, "size"> & {
  size?: HeadingSizeVariants;
  tag?: HeadingTagsVariants;
};
type HeadingProps = React.ComponentProps<typeof DEFAULT_TAG> &
  HeadingVariants & { css?: CSS; as?: any };

export const Heading = React.forwardRef<
  React.ElementRef<typeof DEFAULT_TAG>,
  HeadingProps
>((props, forwardedRef) => {
  const { size = "4xl",tag = DEFAULT_TAG, ...textProps } = props;

  const textSize: Record<HeadingSizeVariants, TextSizeVariants> = {
    "xl": { "@initial": "xl" },
    "2xl": { "@initial": "2xl" },
    "3xl": "3xl",
    "4xl": "4xl",
    "5xl": "5xl",
    "6xl": {"@initial": "3xl","@sm": "3xl"},

  };

  const headingTag : Partial<Record<HeadingTagsVariants, CSS>> = {
    "h1": { 
      fontSize:"$3xl" ,
      lineHeight: "$3xl",
      "@sm" : {
        fontSize:"$4xl" ,
        lineHeight: "$4xl",
      },
      "@md":{
        fontSize:"$5xl" ,
        lineHeight: "$5xl",
      },
      "@xxl" : {
        fontSize:"$6xl" ,
        lineHeight: "$6xl",
        letterSpacing:"$2",
        fontWeight:"$normal"
      }
     },
     "h2":{
      fontSize:"$3xl" ,
      lineHeight: "$3xl",
      "@sm" : {
        fontSize:"$3xl" ,
        lineHeight: "$3xl",
      },
      "@md":{
        fontSize:"$4xl" ,
        lineHeight: "$4xl",
      },
      "@lg":{
        fontSize:"$4xl",
        lineHeight: "$4xl",
      },
      "@xl":{
        fontSize:"$4xl",
        lineHeight: "$4xl",
      },
      "@xxl":{
        fontSize:"$5xl",
        lineHeight: "$5xl",
      }
       
     },
     "h3": { 
      fontSize:"$2xl",
       lineHeight: "$2xl",
       textAlign:"left",
       "@sm" : {
        fontSize:"$2xl" ,
        lineHeight: "$2xl",
        fontWeight:"$normal",
        textAlign:"center",
      },
      "@md" : {
        fontSize:"$3xl" ,
        lineHeight: "$3xl",
        fontWeight:"$normal",
        textAlign:"center",
      },
      "@lg" : {
        fontSize:"$3xl" ,
        lineHeight: "$3xl",
        fontWeight:"$normal",
        textAlign:"center",
      },
      "@xl" : {
        fontSize:"$3xl" ,
        lineHeight: "$3xl",
        fontWeight:"$normal",
        textAlign:"center",
      },
       "@xxl" : {
        fontSize:"$4xl" ,
        lineHeight: "$4xl",
        fontWeight:"$normal",
        textAlign:"center",
      }
     },
     "h4": { 
      fontSize:"$xl",
       lineHeight: "$lg",
       textAlign:"center",
       "@sm" : {
        fontSize:"$2xl",
        lineHeight: "$xl",
        fontWeight:"$normal",
        textAlign:"center",
      },
      "@md" : {
        fontSize:"$2xl",
        lineHeight: "$2xl",
        fontWeight:"$normal",
        textAlign:"center",
      },
       "@xl" : {
        fontSize:"$2xl",
       lineHeight: "$2xl",
        fontWeight:"$normal",
        textAlign:"center",

      },
      "@xxl" : {
        fontSize:"$3xl",
       lineHeight: "$3xl",
        fontWeight:"$normal",
        textAlign:"center",

      }
     },
     "h5": { 
      fontSize:"$lg",
      lineHeight: "$sm",
       "@md":{
        fontSize:"$2xl",
        lineHeight: "$2xl",
        textAlign:"center",
       }
     },
     "h6": { 
      fontSize:"$xl",
       lineHeight: "$lg",
       textAlign:"center",
       "@sm":{
        fontSize:"$2xl",
        lineHeight: "$xl",
       }
     },
  };

  // This is the mapping of Heading Variants to Text css
  const textCss: Partial<Record<HeadingSizeVariants, CSS>> = {
    xl: { lineHeight: 1.33, "@xl": { lineHeight: 1.2 } },
    "2xl": { "@xl": { lineHeight: 1 } },
    "3xl": { lineHeight: 1 },
    "4xl": { lineHeight: 1 },
    "6xl": {"@sm_max": {
      fontSize:"$2xl",
      lineHeight: "$3xl",
    }},

  };

  return (
    <Text
      as={tag}
      {...textProps}
      ref={forwardedRef}
      size={textSize[size]}
      css={{
        fontFamily: "Amazonica",
        fontWeight: "$normal",
        letterSpacing: "$2",
        ...merge(textCss[size],headingTag[tag], textProps.css),
      }}
    />
  );
});

Heading.displayName = "Heading";