import { Box, Button, Container } from "components/elements";
import { Flex } from "components/elements/Flex";
import { Heading } from "components/elements/Heading";
import TextEffect from "components/elements/TextEffect";
import { CircleLogo } from "components/Icons";
import { LoadContext } from "context/LoadContext";
import gsap from "gsap";
import useViewport from "hooks/useViewport";
import { useContext, useLayoutEffect, useRef, useState } from "react";
import animateText from "utils/animateText";
import { isSizeLG } from "utils/mediaQueryParser";
import { BannerDescription, BannerText } from "./styles";

const Banner = () => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const bannerRef = useRef<HTMLDivElement>(null)
  const refDescription = useRef<HTMLDivElement>(null)
  const launchBtnRef = useRef<HTMLDivElement>(null)
  const {setLoadingValue} = useContext(LoadContext)
  const [animationTriggered,setAnimationTriggered]= useState(false)
  const {width} = useViewport()
    useLayoutEffect(() => {
      let timeoutId : ReturnType<typeof setTimeout>
      let box1 =  document.querySelector<HTMLDivElement>('.box1')
      let box2 =  document.querySelector<HTMLDivElement>('.box2')
      let mobileBox1 =  document.querySelector<HTMLDivElement>('.mobile-box1')
      let mobileBox2 =  document.querySelector<HTMLDivElement>('.mobile-box2')
      let body = document.querySelector('html')
      if(body){
        body.style.overflowY="hidden"
      }
      //@ts-ignore
      videoRef?.current.addEventListener('loadeddata', function() {
        // video is ready to play
        console.log('ready to play');
        
        setTimeout(() => {
          if(videoRef.current){
            videoRef.current.style.visibility = 'visible'
          }
          triggerAnimations()
        },2400)
        
     }, true);

     animateText('#bannerLogo',1.2,300,1.2)
     animateText('#bannerText',1.2,100,1.2)
      setTimeout(() => {
        if(bannerRef.current){
          bannerRef.current.style.visibility = 'visible'
        }
      },1200)
      let ctx : gsap.Context
      const triggerAnimations = () => {
        if(refDescription.current){
          refDescription.current.style.visibility = 'visible'
        }
        if(launchBtnRef.current){
          launchBtnRef.current.style.visibility = 'visible'
        }
        if(box1 && box2){
          box1.style.visibility = 'visible'
          box2.style.visibility = 'visible'
        } if(mobileBox1 && mobileBox2){
          mobileBox1.style.visibility = 'visible'
          mobileBox2.style.visibility = 'visible'
        }
        ctx = gsap.context(() => {
          gsap.fromTo(".box1,.mobile-box1",{
            y: width > isSizeLG() ? -74 : -50 ,
          } , {
            duration: 0.5,
            delay:0.5,
            y: 0,
            ease:"power1.in"
          })
          gsap.fromTo(".box2,.mobile-box2", {
            y: width > isSizeLG() ? -74 : -50,
          }, {
            duration: 0.5,
            y: 0,
            delay:0.5,
            ease:"power1.in"
          })
         
          gsap.fromTo('.revealText', {
            y: refDescription.current?.getBoundingClientRect().y,
          },{
            y: 0,
            ease:"power1.inOut",
            duration: 0.5,
            delay:0.5,

  
          });
          gsap.fromTo('.mobile-launch-btn', {
            y: launchBtnRef.current?.getBoundingClientRect().y,
          },{
            y: 0,
            ease:"power1.inOut",
            duration: 0.5,
            delay:0.8,

  
          });
          
          if(body){
            setLoadingValue(false)
            body.style.overflowY="initial"
          }
        });
        timeoutId = setTimeout(() => {
          setAnimationTriggered(true)
        },1500)
      }

      return () => {
        ctx && ctx.revert(); // <- cleanup!
        clearTimeout(timeoutId)
      }
    }, []);
  return (
      <Box css={{height:"100vh",position:"relative"}}>
          <Container css={{position:"relative",height:"inherit"}}>
          <Flex direction="column" justify="center" align="center" css={{
            m:"auto",
            textAlign:"center",
            position:"inherit",
            zIndex:"1",
            height:"inherit",
            display:"flex",
            "@xl":{
              height:"inherit",
              display:"flex",
              paddingTop:"unset",

            }
        }}>
            <Flex css={{visibility:"hidden","@xl":{marginTop:"unset"}}}  ref={bannerRef} direction="column" align="center">
              <Box className="bannerLogo" css={{display:"flex"}}>
              <CircleLogo className={'bannerCoverLogo'}/>
              <TextEffect url=":bannerLogo:" id="bannerLogo"/>
              </Box>
              <BannerText>
              <Heading className="shadowText bannerText mainText" tag={"h1"}>Welcome To The <Box as="span">Future</Box> {width >  isSizeLG() ? <br/> : null} of NFT-FI</Heading>
              </BannerText>
                 <BannerDescription ref={refDescription} className={'revealText'}>
                  Unlock the magic of NFT liquidity with  <Box as="span">
                    LiquiDevil
                  </Box>
                </BannerDescription>
               <TextEffect url=":bannerText:" id="bannerText"/>
             {animationTriggered ? (
              <Box
              ref={launchBtnRef}
              className="mobile-launch-btn"
              css={{visibility:"hidden",mt:"$space$5",display:"block",
              "@md":{
               mt:"$space$6"
             },"@lg":{
               display:"none"
             }}}
              >
              <Box
               className='animate-border' css={{position:"relative"}} >
               <Button css={{
                 backgroundColor: "$colors$slate5",
                 borderRadius: "24px",
                 $$padding: "10px 24px",
                 height:"24px",
                 $$backgroundColor:"$colors$emphasizedText",
                 }}>
                   <Box as="span" className="innerBtn" css={{
                     display:"inline-block",
                     background: "$colors$gradient6",
                     "-webkit-background-clip": "text",
                     "-webkit-text-fill-color": "transparent",
                     backgroundClip: "text",
                     lineHeight:"$lg",
                     fontSize:"$lg",
                     fontFamily:"LatoBold",
                     $$backgroundColor:"$colors$emphasizedText",
                   }}>
                  Launch App
                   </Box>
                 </Button>
             </Box>
              </Box>
             ) : (
              <Box
              ref={launchBtnRef}
            className={'initial-border mobile-launch-btn'} css={{visibility:"hidden",mt:"$space$5",display:"block",
            "@md":{
              mt:"$space$6"
            },"@lg":{
              display:"none"
            }}}>
              <Button css={{
                backgroundColor: "$colors$slate5",
                borderRadius: "24px",
                $$padding: "10px 24px",
                height:"24px",
                $$backgroundColor:"$colors$emphasizedText",
                }}>
                  <Box as="span" className="innerBtn" css={{
                    display:"inline-block",
                    background: "$colors$gradient6",
                    "-webkit-background-clip": "text",
                    "-webkit-text-fill-color": "transparent",
                    backgroundClip: "text",
                    lineHeight:"$lg",
                    fontSize:"$lg",
                    fontFamily:"LatoBold",
                    $$backgroundColor:"$colors$emphasizedText",
                  }}>
                 Launch App
                  </Box>
                </Button>
            </Box>
             )}
            </Flex>
          </Flex>

          </Container>
        <Box as={"video"} ref={videoRef} css={{
           objectFit: "cover",
           width: "100vw",
           height: "100vh",
           position: "absolute",
           visibility:"hidden",
           top: 0,
           left: 0
        }} autoPlay loop muted playsInline>
            <source src="https://liquidevil-app-assets-public.s3.ap-south-1.amazonaws.com/videos/LiquiDevilVideo.mp4" type="video/mp4" />
        </Box>
      </Box>
  );
};

export default Banner;
