import * as React from "react";
import { SVGProps } from "react";
const SvgDiscord = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={40}
    height={40}
    viewBox="0 0 40 40"
    fill="#FFFFEF"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M33.862 6.926A32.986 32.986 0 0 0 25.72 4.4a.123.123 0 0 0-.131.061 22.969 22.969 0 0 0-1.014 2.083 30.452 30.452 0 0 0-9.144 0 21.092 21.092 0 0 0-1.03-2.083.129.129 0 0 0-.13-.061 32.894 32.894 0 0 0-8.143 2.525.116.116 0 0 0-.053.046C.889 14.719-.532 22.276.165 29.739a.137.137 0 0 0 .052.094c3.422 2.512 6.736 4.038 9.989 5.049a.13.13 0 0 0 .14-.046 23.701 23.701 0 0 0 2.043-3.324.127.127 0 0 0-.07-.176 21.844 21.844 0 0 1-3.12-1.487.128.128 0 0 1-.012-.213c.21-.157.42-.32.62-.486a.124.124 0 0 1 .129-.017c6.546 2.988 13.633 2.988 20.102 0a.123.123 0 0 1 .131.016c.2.165.41.33.621.487a.128.128 0 0 1-.01.213 20.492 20.492 0 0 1-3.123 1.485.128.128 0 0 0-.067.178 26.63 26.63 0 0 0 2.041 3.322.127.127 0 0 0 .14.048c3.27-1.011 6.583-2.537 10.005-5.05a.128.128 0 0 0 .052-.091c.834-8.629-1.397-16.123-5.914-22.768a.102.102 0 0 0-.052-.047ZM13.367 25.195c-1.971 0-3.595-1.81-3.595-4.032s1.592-4.031 3.595-4.031c2.018 0 3.626 1.825 3.595 4.031 0 2.222-1.593 4.032-3.595 4.032Zm13.29 0c-1.97 0-3.594-1.81-3.594-4.032s1.593-4.031 3.595-4.031c2.018 0 3.626 1.825 3.595 4.031 0 2.222-1.577 4.032-3.595 4.032Z"
    />
  </svg>
);
export default SvgDiscord;
