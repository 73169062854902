

interface svgProps {
    id?: string,
    url?:string
}
const TextEffect = ({id,url} : svgProps) => (
    <svg
    viewBox="0 0 100 100"
    colorInterpolationFilters="sRGB"
    style={{position:"absolute",width:0,height:0}}
    >
    <defs>
        <filter id={url}>
        <feTurbulence
            type="fractalNoise"
            baseFrequency="0.025"
            numOctaves="1"
            result="turbulence"
        ></feTurbulence>
        <feDisplacementMap
            in2="turbulence"
            in="SourceGraphic"
            id={id}
            scale="100"
            className="myText"
            xChannelSelector="R"
            yChannelSelector="G"
        ></feDisplacementMap>
        <feComposite
            operator="in"
            in="SourceGraphic"
            result="intermediate-1"
        ></feComposite>
        </filter>
    </defs>
    </svg>
);


export default TextEffect;
