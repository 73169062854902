import { styled } from "theme";

export const GalleryDescription = styled('div', {
    ".galleryText":{
        fontFamily: "LatoLight", 
        m: "$space$11 0 $space$6 0", 
        "@md":{
            m: "40px 0 !important", 
            letterSpacing:"$letterSpacings$4",
        },
        "@lg": 
        { 
            m: "$space$4 0 $space$8 0 !important", 
            letterSpacing: "$letterSpacings$2" 
        }
    }
})