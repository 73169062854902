import BorderLine from "components/BorderLine";
import { Container } from "components/elements";
import { Flex } from "components/elements/Flex";
import { Heading } from "components/elements/Heading";
import Details from "./components/Detail";
const Offers = () => {
  return (
    <Container  className="stickyCards" css={{zIndex:3}}>
         <Flex direction="column" align="center">
        <Heading tag="h4" css={{ color: "$emphasizedText", textAlign: "center" ,p:"0 23px" , 
        "@md":{
          maxWidth:"586px",
          padding:"unset !important",

        },
        "@lg":{
          maxWidth:"unset"
        }}} size="4xl" >
          Make a deal with the liquidity Devil 😈
        </Heading>
      </Flex>
      <Details />
      {/* <BorderLine/> */}
     </Container>

  );
};

export default Offers;