import { Box, Container } from "components/elements";
import { Flex } from "components/elements/Flex";
import { Heading } from "components/elements/Heading";
import { Paragraph } from "components/elements/Paragraph";
import { CopyRights, FooterLogo } from "components/Icons";
import { useContext, useEffect, useRef } from "react";
import { styled } from "theme";
import SocialLinks from "./components/SocialLinks";
import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import TextEffect from "components/elements/TextEffect";
import useViewport from "hooks/useViewport";
import { isSizeLG } from "utils/mediaQueryParser";
import toNumber from "utils/numbers";
import { LoadContext } from "context/LoadContext";
gsap.registerPlugin(ScrollTrigger);

const Features = () => {
  const {width} = useViewport()
  const ref1 = useRef<HTMLDivElement>(null)
  const ref2 = useRef<HTMLDivElement>(null)
  const ref3 = useRef<HTMLDivElement>(null)
  const paragraphRefs   = useRef<HTMLDivElement[]>([]);
  const {loading} = useContext(LoadContext)
  paragraphRefs.current = []

  const openTab = (url : string) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

   useEffect(() => {
    let ctx : gsap.Context
    
    if(!loading){
      ctx = gsap.context(() => {
        gsap.utils.toArray(".anim1,.anim2").forEach((el:any,i) => {
  
          let tl =  gsap.timeline({
            scrollTrigger: {
              trigger: `.anim${i+1}`,
              pin: false,
              scrub: true,
              start: 'top bottom',
              end: `+=${el.clientHeight + toNumber(getComputedStyle(paragraphRefs.current[i]).marginTop) + (i === 0 ? toNumber(getComputedStyle(paragraphRefs.current[i]).height) : (2 * (toNumber(getComputedStyle(paragraphRefs.current[i]).height))))}`,
              markers: false,
              once:true,
              toggleActions:"restart pause reverse none",
            }
          })
          tl.fromTo(`#footerText${i+1}`, {
            'scale': 100,
            attr: {scale: 100},
          },{
              duration: 10,
            ease: "none",
            'scale': 0,
              attr: {scale: 0},
          });
        })
  
      })
    }

    return () => ctx && ctx.revert(); // <- cleanup!

  },[loading])

  const menu = [
    {
      name: 'About Us',
      link: 'https://calendly.com/liquidevil '
    },
    {
      name: 'FAQ',
      link: 'https://docs.liquidevil.xyz/'
    },
    // {
    //   name: 'Careers',
    //   link: ''
    // },
    {
      name: 'Docs',
      link: 'https://docs.liquidevil.xyz/'
    },
    {
      name: 'Whitepaper',
      link: ''
    },
  ]
  
  return (
    <Box css={{ position: "relative", zIndex: 10,pt:"$space$6",
    "@md":{
      pt:"96px"
    },
    "@lg":{
      pt:"140px"
    } }} className="landingBg">
      <Container  css={{ p: "0 0 $space$9 0" }} >
        <Flex align="center" direction="column" justify="between" css={{"@lg":{
          flexDirection:"row",
          p: "0 56px 237px 56px"
        },"@xl":{
          p: "0 112px 237px 112px"

        }}}>
          <Box css={{ textAlign: "center","@lg":{
            // width:"50%"
          },"@xl":{
            // width:"initial"
          } }}>
            <Heading tag={"h1"} ref={ref1} className="footerText1 anim1">Devil’s Family</Heading>
            <TextEffect url=":footerText1:" id="footerText1"/>
            <Paragraph
            ref={(el) => el  && !paragraphRefs.current.includes(el) && paragraphRefs?.current.push(el)}
            css={{ fontFamily: "LatoLight", mt: "$space$2", color: "$colors$emphasizedText",
            "@lg":{
              maxWidth:"462px !important"
            },ml:"auto",mr:"auto" }} size="2xl">
              Let’s embark on this new <br/> adventure together
            </Paragraph>
          </Box>
          <Box css={{
            m:"40px 0 162px 0",
            "@md":{
              m:"80px 0 241px 0"
            },
            "@lg":{
              m:"initial"
            }
          }}>
          <Box css={{position:"relative"}}>


            <Box css={{overflow:"visible",width:"200px",height:"200px",position: "absolute",
            zIndex: -1,
            top:"50%",
            left: "58%",
            transform: "translate(-42%, -50%)",
            "@sm":{
              left: "50%",
              transform: "translate(-50%, -50%)",
              top: "50%",
  
              width:"280px",height:"280px"
            },
            "@lg":{
              width:"360px",height:"360px"
            }
             }}>
              <Box as="img" src={"assets/images/glowvideo.png"} alt=""
              css={{ $$color: "$colors$secondary3", position:"absolute",left:"50%",top:"44%",transform:"translate(-54%,-50%)" ,width:"628px",height:"628px",zIndex:-1,
              "@sm":{
                left:"50%",
                // width:"752px",
                width:"initial !important",
                height:"initial !important",
                top:"50%",
                // height:"752px",
                transform:"translate(-50%, -50%)",

              }}}
              >
              </Box>
            </Box>

<LogoAnimation>


  <Box css={{border: "1.5px solid $colors$primary3",padding:"5px",borderRadius:"50%"}}>
<Box css={{backgroundColor:"$black",borderRadius:"50%",position:"sticky",display:"flex"}}>

<Box as={"video"}
css={{
  objectFit: "cover",
  borderRadius:"50%",
  // width: "90px",
  // height: "90px",
  // "@xl":{
  //   width: "170px",
  //   height: "170px",
  // }
  
}}
autoPlay loop muted playsInline>
<source src="https://liquidevil-app-assets-public.s3.ap-south-1.amazonaws.com/videos/Logo.mp4" type="video/mp4" />
</Box>
</Box>
</Box>
</LogoAnimation>

            {/* 48px 0 128px 0 */}
            <Box css={{m:"0",display:"none" ,"@lg":{
              m:"initial"
            }}}>
              <Box as={'img'} css={{width:"100%",display:"none","@lg":{
                visibility:"hidden",
                display:"none",
              }}} src="assets/icons/Ecosystem.svg" alt=""  />
            </Box>
            {/* <Box css={{ $$color: "$colors$secondary3",left:"0",bottom:"0" ,opacity:"0.4",width:"130px",zIndex:-1,height:"130px","@xl":{
              zIndex:-9999,
              width:"240px",
              height:"240px"
            }}}> */}
              {/* <Box as="img" src={width > isSizeLG() ? "assets/images/glowecosystem.png" : "assets/images/glowecosystemres.png"} alt=""
              css={{ $$color: "$colors$secondary3", position:"absolute",left:"50%",top:"44%",transform:"translate(-54%,-50%)" ,width:"initial",height:"initial",zIndex:-1,"@lg":{
                left:"45%",
                width:"752px",
                top:"50%",
                height:"752px",
                transform:"translate(-55%, -50%)",

              }}}
              >
              </Box> */}
            {/* </Box> */}
            {/* <Ecosystem style={{width: width > 600 ? "initial":"100%"}}/> */}
            {/* <GradientShadow css={{ $$color: "$colors$secondary3",left:"21%",top:"29%" ,opacity:"0.4",width:"130px",zIndex:-1,height:"130px","@xl":{
              zIndex:-9999,
              width:"200px",
              height:"200px"
            }}} /> */}

    
          </Box>
          </Box>
        </Flex>
        <SocialLinks />
        <Box css={{ mt: "50px",position:"relative" ,
        "@sm":{
          mt:"124px",
        },
        "@lg":{
          mt: "$space$14"
        }}}>
        {/* <GradientShadow css={{ $$color: "$colors$secondary3",right:"-40%",top:"16%" ,opacity:"0.2",$$blur:"64px",width:"257px",height:"240px","@xl":{
          right:"-21%",
          top:"-36%" ,
          width:"515px",
          height:"480px"
        }}} /> */}
          <Box as="img" src={width >  isSizeLG() ? "assets/images/glowcommunitybottom.png" : "assets/images/glowcommutnityBottomResponsive.png"} alt=""
              css={{ $$color: "$colors$secondary3", position:"absolute",top:"50%",left:"75%",transform:"translate(-25%, -50%)" ,zIndex:-1,
              "@sm":{
                top: "55%",
                width: "800px",
                left: "70%",
                transform: "translate(-30%, -45%)",
              },
              "@lg":{
                width:"1027px",
                height:"992px",
                left:"unset",
                // right:"-37%",
                // bottom:"-109%"
                top:"50%",
                right:0,
                transform:"translate(50%, -50%)",

              }}}
              >
          </Box>
        
          <Heading className="footerText2 anim2" ref={ref3} tag={"h2"} css={{ color: "$colors$brown3",fontSize:"$2xl", lineHeight:"$2xl",marginBottom:"8px","@sm":{
            marginBottom:"initial !important"
          }}}>Know Your Devil (KYD)</Heading>
            <TextEffect url=":footerText2:" id="footerText2"/>

          <Flex
          direction="column" css={{"@md":{
            flexDirection:"row"
          }}}>
            {menu.map((list, index) => (
              <Paragraph
              onClick={() => openTab(list.link)}
              ref={(el) => el  && !paragraphRefs.current.includes(el) && paragraphRefs?.current.push(el)}
              size="md" key={index} css={{ fontFamily: "LatoRegular", mt: "$space$1", mr: "$space$8", color: "$colors$emphasizedText","&:hover":{
                color:"$colors$brown3",
                cursor:"pointer"
              },
              "@sm":{
                marginTop:"$space$3 !important",
              },
              "@lg":{
                marginTop:"$space$3",
                mr:"$space$6"
              },"@xl":{
                mr: "$space$8 !important"
              }}}>
                {list.name}
              </Paragraph>
            ))}
          </Flex>
          <OulineText tag={"h1"}>Yes! He’s everywhere!!!</OulineText>

        </Box>
        <Box css={{ mt: "$space$6",
        "@lg":{
          textAlign:"end",
          mt: "$space$20"
        }, }}>
          <Flex align="center" css={{mb:"$space$1","@lg":{
            justifyContent:"flex-end" ,
            alignItems:"flex-end"
          }}}>
            <Box className="footerlogo">
            <FooterLogo />
            </Box>
            <Heading css={{ml:"6px","-webkit-text-stroke": "0.15px",letterSpacing:"$2","@md":{letterSpacing:"$4","-webkit-text-stroke": "0.2px !important",ml:"$space$2 !important"}}} tag={"h5"} >LiquiDevil</Heading>
          </Flex>
          <Flex align="center" css={{"@lg":{
              justifyContent:"flex-end"
            
          }}}>
            <CopyRights />
            <FooterText>
              LiquiDevil, 2023, All rights reserved.
            </FooterText>
          </Flex>

        </Box>
      </Container>
    </Box>

  );
};

export default Features;

const FooterText = styled('div',{
  fontFamily: "LatoRegular",
  lineHeight: "$sm",
  color: "$colors$slate4",
  ml:"8px" ,
  fontSize:"$sm"
})

const OulineText = styled(Heading, {
  marginTop: "$space$5",
  "-webkit-text-stroke": "0.2px transparent",

  background: "-webkit-linear-gradient(#FFFFEF 91%, #FFFFEF 91%)",
  "-webkit-background-clip": "text",
  color: "#000E10",
  fontSize:"$xl !important",
  lineHeight:"$lg !important",
  "@sm":{
    marginTop: "$space$9",
    fontSize:"$3xl !important",
    lineHeight:"$3xl !important",
  },
  "@md":{
    marginTop: "$space$9",
    fontSize:"$4xl !important",
    lineHeight:"$4xl !important",
    "-webkit-text-stroke": "0.4px transparent",
  },
  "@lg":{
    marginTop: "$space$7",
    fontSize:"$5xl !important",
    lineHeight:"$5xl !important",
  },
  "@xl":{
    fontSize:"$6xl !important",
    lineHeight:"$6xl !important",
    marginTop: "$space$7",
    "-webkit-text-stroke": "0.7px transparent",

  }

})

const LogoAnimation = styled('div',{
  // position: "absolute",
    // width:"28%",
    width:"160px",
    // m:"65px 0 186px 0",
    // top:"30%",
    // left:"24%",
    overflow:"hidden",
    borderRadius:"50%",
    // transform: "translate(-50%, -50%)",
    top:"50%",
    left:"50%",
    padding:"20px 0",
    "@md":{
      m:"initial",
      width:"224px",
      padding:"28px 0",

    },
    
    "@lg":{
      padding:"36px 36px",

      // position: "absolute",
      m:"initial",
      // transform: "translate(-57%, -50%)",
      // top:"50%",
      // left:"43%",
      width:"288px",

      // top:"31%",
     
    }
    // "@xl":{
      // top: "31%",
      // left: "23%",
    // }

})
