import { styled } from "theme";

export const BannerText = styled('div', {
    ".mainText": {
        pt: "$space$4",
        "@md": {
            pt: "$space$6"
        }, "@lg": {
            pt: "$space$5"
        },
        span: {
            display: "block",
            "@lg": {
                display: "inline"
            }
        }
    }
})

export const BannerDescription = styled('div', {
        fontFamily: "LatoRegular", 
        letterSpacing: "$letterSpacings$1", 
        fontSize: "$lg", 
        lineHeight: "$md", 
        visibility: "hidden",
        mt: "$space$4",
        "@md":{
            fontFamily: "LatoSemiBold", 
            letterSpacing: "$letterSpacings$2", 
            mt:"$space$6",
            lineHeight:"32px",
            fontSize:"$xl",
        },
        "@lg":{
            letterSpacing: "$letterSpacings$1", 
            fontFamily: "LatoRegular", 
            mt:"$space$4 !important",
            fontSize:"$xl",
            lineHeight:"$2xl", 
        },
        "@xl":{
            fontSize:"$xl", 
            lineHeight:"$2xl", 
        },"@xxl":{
            fontSize:"$2xl", 
            lineHeight:"$3xl"
        },
        "span":{
            display: "inline",
            "@md": {
                display: "block"
            },
            "@lg":{
                display:"inline"
            }
        }
    
})