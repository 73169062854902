
import { LoadContext } from "context/LoadContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { SliderWrapper } from "./styles";


const Slider2 = React.forwardRef<HTMLDivElement>((props,ref) => {
  
  const revealRefs   = useRef<HTMLImageElement[]>([]);
  revealRefs.current = []
  const galleryImages = [
    "assets/images/nfts/nft1.png",
    "assets/images/nfts/nft2.png",
    "assets/images/nfts/nft3.png",
    "assets/images/nfts/nft4.png",
    "assets/images/nfts/nft5.png",
    "assets/images/nfts/nft6.png",
    "assets/images/nfts/nft7.png",
    "assets/images/nfts/nft8.png",
    "assets/images/nfts/nft9.png",
    "assets/images/nfts/nft10.png",
    "assets/images/nfts/nft11.png",
    "assets/images/nfts/nft12.png",
    "assets/images/nfts/nft13.png",
    "assets/images/nfts/nft14.png",
    "assets/images/nfts/nft15.png",
  ];
  const classes = [
    "xxxlleft stylexxxl",
    "xxlleft stylexxl",
    "xlleft stylexl",
    "stylel",
    "xlright stylexl",
    "xxlright stylexxl",
    "xxxlright stylexxxl"
  ]
  const [counter,setCounter] = useState(galleryImages.length)
  const [indexes, setIndexes] = useState(Array.from({length: galleryImages.length}).map((_, i) => i ) );
  const [isScrolledIntoView,setIsScrolledIntoView] = useState(false)
  const galleryRef  = useRef<HTMLDivElement>(null)
  const {loading} = useContext(LoadContext)
  const [isGalleryLoad,setIsGalleryLoad] = useState<boolean>(false)  
  useEffect(() => {
    if(!loading){
      let observer : IntersectionObserver
    observer = new IntersectionObserver(
      (entries) => {        
        if(!isScrolledIntoView &&  entries[0].intersectionRect.height + 5 >= entries[0].boundingClientRect.height){
          console.log('gallery in view');
          setIsScrolledIntoView(true)
          observer.disconnect()
        }
      },
      {
        root: null,
        rootMargin: "0px",
        //@ts-ignore
        threshold: [...Array(100).keys()].map(x => x / 100),
      }
    );

    if(galleryRef.current){
     observer.observe(galleryRef.current)
    }
    }

  },[loading])

  const imageLoading = (idx: any) => {
    console.log('image index',idx);
    setCounter((prev) => prev - 1)
  }

  useEffect(() => {
   let intervalId : ReturnType<typeof setInterval>
   let timeoutId : ReturnType<typeof setTimeout>

    
   if(isScrolledIntoView){
        timeoutId = setTimeout(() => {
        intervalId = setInterval(() => {
          setIndexes((prev) => {
            const newd = [...prev.slice(1)];
            newd.push(prev[0]);
            return newd;
          });
        }, 2000);
      },500)
     
    }
    return () => {
      clearInterval(intervalId)
      clearTimeout(timeoutId)
    }
  }, [isScrolledIntoView]);

  useEffect(() => {
    console.log('counter',counter);
    if(counter <= 0){
      setIsGalleryLoad(true)
    }
  },[counter])

  return (
    <SliderWrapper ref={galleryRef} css={{visibility:isGalleryLoad  ? 'visible !important':"hidden"}}>
      {
        !loading && indexes.map((index,idx) => {       
           return <div className={classes[idx]} key={index} style={{margin:"0 6px"}} data-index={index}>
                <img 
                onLoad={() => imageLoading(idx)}
                  style={{width:"100%",height:"100%"}} 
                  src={galleryImages[index]} 
                  alt="unknown"  
                />
            </div>
          
        }
        )
      }
    </SliderWrapper>
  );
});

export default Slider2;
