import { Paragraph } from "components/elements/Paragraph";
import { keyframes, styled } from "theme";

export const Links = styled(Paragraph, {
    fontFamily: "LatoRegular",
    color: "inherit",
  })
export const StyledMobileMenuWrapper = styled('div', {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1000,
  });
  export const fade = keyframes({
    '0%': {
      opacity: '0'
    },
    "100%": {
      opacity: '1'
    }
  });
  export const fadeOut = keyframes({
    '0%': {
      opacity: '1'
    },
    "100%": {
      opacity: '0'
    }
  });
  export const StyledBackdrop = styled("div", {
    backgroundColor: '$colors$bgColor',
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    animation: `${fade}  1s forwards ease-out`,
    "&.closed":{
      animation: `${fadeOut}  1s forwards ease-out`,
    },
  });
  export const slideIn = keyframes({
    '0%': {
      transform: 'translateX(0)'
    },
    "100%": {
      transform: 'translateX(-100%)'
    }
  });
  export const StyledMobileMenu = styled('div', {
    animation: `${fade}  1s forwards ease-out`,
    transform: 'translateX(-100%)',
    display: "flex",
    flex: 1,
    flexDirection: 'column',
    position: "relative",
    top: 0,
    left: "100%",
    bottom: 0,
    // padding: "$space$10 $space$10 0 $space$10",
    width: '100%',
    height: '100%',
    maxWidth: '100%',
    overflow: 'hidden',
    "&.closed":{
      animation: `${fadeOut}  1s forwards ease-out`,
    },
    ".closeIcon":{
      postion:"absolute !important",
      top:"-7px",
      right:"5px",
      width:"16px",
      height:"16px",
    },
    '&.disabled': {
      pointerEvents: "none",
      opacity: 0.4,
    },
  });

  