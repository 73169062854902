import * as React from "react";
import { SVGProps } from "react";
const SvgMaskLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    fill="none"
    width="427" height="828" 
    viewBox="0 0 427 828"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#MaskLeft_svg__a)" fill="#fff" fillOpacity={0.05}>
      <path d="M580.981 233.741c8.722 29.425 16.067 80.4-4.591 147.124 0 0-11.935 46.832-61.053 71.283 0 0-36.724 17.821-36.724 55.12v48.074s-47.283 13.677-103.287 80.815h-37.183c-55.545-67.138-102.827-80.815-102.827-80.815v-48.488c0-37.299-36.724-55.12-36.724-55.12-49.578-24.452-61.513-71.283-61.513-71.283-20.657-67.553-12.853-119.357-4.131-148.368 0 .415-.459.829-.459 1.244 0 0 2.754 114.384 112.008 188.568 0 0 16.067 11.189 33.052 14.505 2.295.414 3.213.829 3.213.829 12.394 2.072 20.657 6.631 20.657 6.631v-.415c.459 0 .459.415.459.415v-2.487c.918-50.976-54.627-87.446-54.627-87.446-22.952-14.505-39.478-29.839-51.413-45.173-.459-.415-.919-1.243-1.378-1.658-.459-.414-.918-.829-.918-1.243-.918-1.244-1.377-2.072-2.295-3.316-.918-.829-1.377-2.072-2.295-3.315-.918-1.243-1.836-2.487-2.295-3.73-2.755-4.559-5.05-9.118-7.345-13.262-.459-1.243-.918-2.487-1.377-3.315-.459-1.658-1.378-2.902-1.837-4.559-.459-1.244-.918-2.901-1.836-4.145-1.836-5.387-3.672-10.775-4.59-15.334-.459-1.657-.918-3.73-.918-5.387-.459-1.658-.459-3.316-.918-4.559 0-.829 0-1.658-.46-2.072v-3.316c1.378-38.957-14.23-65.066-14.23-65.066C115.504 92.833 52.614 2.901 50.319 0c2.295 2.072 75.284 69.625 134.042 98.221 0 0 44.987 27.767 100.073 29.425h145.519c55.086-1.658 100.073-29.425 100.073-29.425C587.867 69.625 660.856 2.072 663.151 0c-1.836 2.901-65.185 92.833-104.205 174.063 0 0-15.148 26.109-14.23 65.066 0 0 0 7.045-2.295 18.235-5.968 25.28-23.871 64.652-76.662 96.978 0 0-53.25 34.812-54.627 83.715 0 1.244-.459 2.487-.459 3.73v2.487h.459s8.263-4.559 20.657-6.631c.46 0 .46 0 1.378-.414h1.377c17.444-2.901 34.429-14.92 34.429-14.92 109.713-74.184 112.008-188.568 112.008-188.568Z" />
      <path d="M355.495 808c43.47 0 62.334-14.457 62.334-14.457 37.728-25.61 66.435-22.719 66.435-22.719 61.923 2.065 66.024-40.893 66.024-40.893 4.921-38.415 49.621-64.025 49.621-64.025 47.981-33.459 79.148-96.244 79.148-96.244 57.412-126.811-13.943-218.512-13.943-218.512s64.384 140.029-39.779 244.535c0 0-15.174 18.588-42.239 23.957 0 0-43.06.827-57.003 15.697 0 0-11.893 10.327-25.426 15.696 0 0-22.555 7.022-41.829 39.655 0 0-17.224 25.61-37.728 36.349 0 0-23.786 11.979-48.391 45.437 0 0-9.842 17.762-15.994 18.175h-.41c-6.151-.413-15.993-18.175-15.993-18.175-24.606-33.871-48.391-45.437-48.391-45.437-20.915-10.326-37.728-36.349-37.728-36.349-19.685-32.633-41.829-39.655-41.829-39.655-13.534-5.369-25.426-15.696-25.426-15.696-14.353-14.457-57.003-15.697-57.003-15.697-27.066-5.369-42.24-23.957-42.24-23.957C-18.507 491.179 46.288 351.15 46.288 351.15s-71.356 91.701-13.943 218.512c0 0 31.167 62.785 79.147 96.244 0 0 44.7 25.61 49.621 64.025 0 0 4.101 42.958 66.025 40.893 0 0 28.296-2.891 66.434 22.719-.41 0 18.455 14.457 61.924 14.457Z" />
    </g>
    <defs>
      <filter
        id="MaskLeft_svg__a"
        x={0}
        y={0}
        width={711.4}
        height={828}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={10} />
        <feGaussianBlur stdDeviation={5} />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_403_738" />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_403_738"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy={6} />
        <feGaussianBlur stdDeviation={3} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
        <feBlend in2="shape" result="effect2_innerShadow_403_738" />
      </filter>
    </defs>
  </svg>
);
export default SvgMaskLeft;
