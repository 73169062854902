import React, { useState, useCallback } from 'react'


interface LoadingType {
  loading: boolean,
  setLoadingValue: any
}
const initialState:LoadingType = {
    loading: true,
    setLoadingValue: () => {}
}
const LoadContext = React.createContext(initialState)

const LoadContextProvider = ({ children }: any) => {
  const [loading, setLoading] = useState<boolean>(true)
  const setLoadingValue  = useCallback((value:boolean) => {
    setLoading(value)    
  },[])
  return <LoadContext.Provider value={{ loading ,setLoadingValue}}>{children}</LoadContext.Provider>
}

export { LoadContext, LoadContextProvider }
