import React, { useCallback, useState } from 'react';
 
import { Outlet } from 'react-router-dom';
 import { Box } from 'components/elements';
import Navbar from '../Header/Navbar';
import MobileNavbar from '../Header/MobileNavbar';

const MainLayout: React.FC = () => {
  const [mobileMenu, setMobileMenu] = useState(false);

  const handleDismissMobileMenu = useCallback(() => {
    setMobileMenu(false);
  }, [setMobileMenu]);

  const handlePresentMobileMenu = useCallback(() => {
    setMobileMenu(true);
  }, [setMobileMenu]);

  return (
    <>
      <Navbar onPresentMobileMenu={handlePresentMobileMenu} />
      <MobileNavbar visible={mobileMenu} onDismiss={handleDismissMobileMenu} />
      <Box css={{ pt: '$space$120' }}>
        <Outlet />
      </Box>
    </>
  );
};

export default MainLayout;
