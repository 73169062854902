import React from "react";
import { Text } from "./Text";
import { VariantProps, CSS } from "theme";
import merge from "lodash.merge";

const DEFAULT_TAG = "p";

type TextSizeVariants = Pick<VariantProps<typeof Text>, "size">;
type ParagraphSizeVariants = 
  "xxs" 
  | "xs"
  | "sm"
  | "md"
  | "lg"
  | "xl"
  | "2xl"
type ParagraphVariants = { size?: ParagraphSizeVariants } & Omit<
  VariantProps<typeof Text>,
  "size"
>;
type ParagraphProps = React.ComponentProps<typeof DEFAULT_TAG> &
  ParagraphVariants & { css?: CSS; as?: any };

export const Paragraph = React.forwardRef<
  React.ElementRef<typeof DEFAULT_TAG>,
  ParagraphProps
>((props, forwardedRef) => {
  // '2' here is the default Paragraph size variant
  const { size = "md", ...textProps } = props;

  // This is the mapping of Paragraph Variants to Text variants
  const textSize: Record<ParagraphSizeVariants, TextSizeVariants["size"]> = {
    xxs:"xxs",
    xs:"xs",
    sm:{ "@initial": "md"},
    md: { "@initial": "md","@sm":"lg" ,"@xl":"xl"},
    lg: { "@initial": "sm" , "@sm":"xl","@lg":"md","@xl":"md","@xxl":"lg"},
    xl:{"@initial":"sm","@sm":"lg","@lg":"md","@xl":"lg","@xxl":"xl"},
    "2xl":{"@initial":"sm","@md":"lg","@lg":"xl","@xl":"xl","@xxl":"2xl"}
  };

  // This is the mapping of Paragraph Variants to Text css
  const textCss: Record<ParagraphSizeVariants, CSS> = {
    xxs: { lineHeight: "25px", "@xl": { lineHeight: "27px" } },
    xs: {  },
    sm: { 
      "@xs":{
        lineHeight: "$sm",
        fontSize:"$sm",
      },
      "@sm":{
        fontSize:"$lg",
        lineHeight:"$lg",
      },
      "@md":{
        fontSize:"$lg",
        lineHeight:"$lg",
      },
      "@lg":{
        fontSize:"$md",
        lineHeight:"$md",
      },
      "@xl":{
        fontSize:"$lg",
        lineHeight:"$lg",

      },
      "@xxl":{
        fontSize:"$xl",
        lineHeight:"$xl",
      }
    },
    md: {
      fontSize:"$md",
      lineHeight:"$md", 
      "@sm":{
        fontSize:"$xl",
        lineHeight:"$xl"
      } 
    },
    lg: { 
      lineHeight:"$sm",
      textAlign:"left",
      fontSize:"$sm",
      "@sm":{
        lineHeight:"$md",
        fontSize:"$md",
      },
      // "@lg":{
      //   lineHeight:"$xs",
      //   fontSize:"$sm",
      // },
      "@md":{
        fontSize:"$md",
        textAlign:"center"
      },
      "@lg":{
        lineHeight:"$md",
        fontSize:"$md",
        textAlign:"left"

      },
      "@xxl":{
        lineHeight:"$md",
        fontSize:"$lg",

      }
    },
    xl: {  
      lineHeight:"26px",
      fontSize:"19.2px",
      letterSpacing:"0.08em",
      "@md":{
        fontSize:"$lg !important",
        lineHeight:"$md",
        letterSpacing:"0.04em"
      },
      "@lg":{
        letterSpacing:"0.08em",
        lineHeight:"$md",
        fontSize:"$lg !important",
      },
      "@xl":{
        lineHeight:"$lg",
        fontSize:"$lg !important",
      },
      "@xxl":{
        lineHeight:"32px",
        fontSize:"$xl !important",
      }
     },
    "2xl": {  
      lineHeight:"$sm",
      "@sm":{
        lineHeight:"$lg",
        fontSize:"$lg",
      },
      "@md":{
        lineHeight:"$xl",
        fontSize:"$xl",
      },
      "@lg":{
        fontSize:"$xl",
        lineHeight:"$xl",
      },
      "@xl":{
        fontSize:"$xl",
        lineHeight:"$2xl",
      },
      "@xxl":{
        fontSize:"$2xl",
        lineHeight:"$3xl",
      }
    }

  };
  return (
    <Text
      as={DEFAULT_TAG}
      {...textProps}
      ref={forwardedRef}
      size={textSize[size]}
      css={{
        ...merge(textCss[size], props.css),
      }}
    />
  );
});

Paragraph.displayName = "Paragraph";